import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { api } from '../../../Api';
// component
import Iconify from '../../../components/Iconify';
import { Decryption, Encryption } from 'src/functions/function';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [alert, setalert] = useState({ visible: false, message: '' });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmiting, setisSubmiting] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email OR UserName is required'),
    password: Yup.string().required('Password is required')
  });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const email = queryParams.get('email');

  useEffect(() => {
    if (email) {
      formik.setFieldValue('email', email);
    }
    // eslint-disable-next-line
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      LoginFuction();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };



  const LoginFuction = async () => {
    setisSubmiting(true);

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: Encryption(values.email, process.env.REACT_APP_SECRET_KEY),
        password: Encryption(values.password, process.env.REACT_APP_SECRET_KEY)
      })
    };

    await fetch(api.login, requestOptions)
      .then((response) => response.text())
      .then(async (result_) => {
        const result = JSON.parse(result_);
        console.log(result, 'result');
        if (result.success === true) {
          localStorage.setItem('token', JSON.stringify(result.data.token));
          await getSideBar(result.data.token, result.path);
          localStorage.setItem('user', JSON.stringify(result.user));
          localStorage.setItem('company', JSON.stringify({...result.user?.company, logo: result.user?.company?.logo ? 'https://api.aiautoparts.net/public/storage/' + result.user?.company?.logo : null}));
        } else {
          setalert({ visible: true, message: result.message });
          console.log('Login Else Error');
          //   setalert({ visible: true, message: Object.values(result?.errors)[0][0] });
        }
      })
      .catch((error) => {
        console.log('Login Catch Error', error);
        setisSubmiting(false);
      })
      setisSubmiting(false);
  };

  async function getSideBar(token, path) {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.sideBar, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, 'result get Sidebar');
        if (result.success === true) {
          let decryptedData = result.data;
          console.log(decryptedData, 'YE CHECK KRW');
          localStorage.setItem('permissions', JSON.stringify(result.data));
          setTimeout(() => {
            navigate(path, { replace: false });
          }, 1000);
        } else {
          console.log('result error of sidebar');
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setisSubmiting(false);
      });
  }


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type=""
            label="Email / User Name"
            {...getFieldProps('email')}
            className="custom-background"
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

        </Stack>
        {alert.visible && (
          <Alert
            onClose={() => {
              setalert({ visible: false, message: '' });
            }}
            severity="error"
            style={{ fontSize: 12, marginBottom: 20 }}
          >
            {alert.message}
          </Alert>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmiting}
          contained={isSubmiting.toString()}
        >
          Login
        </LoadingButton>

      </Form>
    </FormikProvider>
  );
}
