import * as React from 'react';
// import Box from '@mui/materia'
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Modal,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
  MenuItem,
  IconButton,
  Paper
} from '@mui/material';
import moment from 'moment';

// components

import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';
import DeductionModal from 'src/sections/@dashboard/user/DeductionModal';
import ActionSheet from 'actionsheet-react';
import MenuPopover from 'src/components/MenuPopover';
import Swal from 'sweetalert2';
import { showSuccessAlert } from 'src/utils/toastifyAlert';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function SettingTab2() {
  
  const [TABLE_HEAD, setTABLE_HEAD] = useState([
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false }
  ]);

  const [permission, setpermission] = useState([]);
  const [showGridActons, setShowGridActons] = useState(false);
  const requirement = ['View', 'Add', 'Edit', 'Delete'];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openPop, setOpenPop] = useState(false);
  const [openPopIndex, setOpenPopIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const sheetRef = React.useRef();


  function checkRender() {
    let check = 'Settings';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    console.log(localPerm);
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );
    ////////////////////  for grid actons ////////////////////

    const requirementGrid = ['Edit', 'Delete', 'Generate', 'Payment History'];

    const checkGridActions = localPerm[0]?.permission_types?.filter((items) =>
      requirementGrid.includes(items.permission_type_name) ? true : false
    );

    if (checkGridActions && checkGridActions.length > 0) {
      setShowGridActons(true);
      setTABLE_HEAD((prevState) => [
        ...prevState,
        { id: 'actions', label: 'Actions', alignCenter: true }
      ]);
    } else {
      setShowGridActons(false);
    }

    ////////////////////////////////////////////////////////////
  }
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');

  const [deductionModal, setDeductionModal] = useState(false);
  const handleDeductionModalClose = () => setDeductionModal(false);

  const refreshdeduction = () => {
    // setDeductionModal(false);
    setDeductionModal(false);
    getDeduction();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getDeduction(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    // console.log(token);
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.getDeduction, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          list.length = 0;
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);

  function DeleteDeduction(id, index) {
    setselectedIndex(index);
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this setting?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'No, cancel',
      confirmButtonColor: pallete.primary.main,
      reverseButtons: true,
      backdrop: false,
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${api.DeleteDeduction}`, requestOptions)
        .then((response) => response.text())
        .then((result_) => {
          const result = JSON.parse(result_);
          if (result.success === true) {
            showSuccessAlert(result.message);
            console.log('Successfully deleted');
            getDeduction(page);
          }
        })
        .catch((error) => {
          console.log('error', error, 'Catch deleted error');
        })
        .finally(() => {
          setdeleting(false);
          setselectedIndex(null);
        });
      } else {
        setdeleting(false);
        setselectedIndex(null);
      }
    });
  }

  useEffect(() => {
    setloading(true);
    checkRender();
    getDeduction();
  }, []);

  const isUserNotFound = list.length === 0;

  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getDeduction(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getDeduction();
    }
  };

  const [deduction, setdeduction] = useState(null);

  return (
    <Page title={`Employees | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* INVENTORY MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={deductionModal}
          onClose={handleDeductionModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal" borderRadius={2}>
            <DeductionModal
              handleClose={handleDeductionModalClose}
              refresh={refreshdeduction}
              data={deduction}
            />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Deduction
          </Typography>

          {permission.includes('Add') && (
            <>
              <Button
                onClick={() => {
                  console.log('it works!');
                  setdeduction(null);
                  setDeductionModal(true);
                }}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Deduction
                {/* Add deduction */}
              </Button>
            </>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder="Search Deductions..."
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{ width: '5%', marginLeft: '2.5%' }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getDeduction();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Box style={{margin:"0px 20px 16px 20px"}}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800, overflow: 'visible', }}>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                  <TableBody>
                    {list.map((row, index) => {
                      const { id, name, price, role } = row;

                      return (
                        <TableRow
                          hover
                          style={{
                            cursor: 'pointer'
                            // Remove fixed height style
                          }}
                          key={id}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="normal">
                            {name && name ? name : 'N/A'}
                          </TableCell>

                          <TableCell>
                            {' '}
                            {/* Set align to "center" */}
                            {price || 'N/A'}
                          </TableCell>
                          {showGridActons && (
                            <>
                              <TableCell align="center" style={{ flexDirection: 'column' }}>
                                {' '}
                                <IconButton
                              onClick={(e) => {
                                if (isMobile) {
                                  sheetRef.current.open();
                                } else {
                                  setOpenPop(!openPop);
                                  setOpenPopIndex(id);
                                  setAnchorEl(e.currentTarget);
                                }
                              }}
                            >
                              <Iconify icon={'ri:more-fill'} />
                            </IconButton>
                            {!isMobile && (
                              <MenuPopover
                                open={openPop && openPopIndex === id}
                                onClose={() => {
                                  setOpenPop(false);
                                  setAnchorEl(null);
                                }}
                                anchorEl={anchorEl}
                                sx={{ width: 220 }}
                              >
                                <Stack flexDirection={'column'}>
                                  {permission.includes('Edit') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setOpenPop(false);
                                        setdeduction(row);
                                        setDeductionModal(true);
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined" color="secondary"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify
                                        color={pallete.primary.main}
                                        icon="tabler:edit"
                                        sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                      />{' '}
                                      Edit
                                    </MenuItem>
                                  )}
                                  {permission.includes('Delete') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setOpenPop(false);
                                        DeleteDeduction(id, index);
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined" color="secondary"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify
                                        color={pallete.primary.main}
                                        icon="ic:baseline-delete"
                                        sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                      />{' '}
                                      Delete
                                    </MenuItem>
                                  )}
                                </Stack>
                              </MenuPopover>
                            )}
                            {isMobile && <ActionSheet
                              ref={sheetRef}
                              sheetStyle={{
                                backgroundColor: 'white',
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20
                              }}
                              bgStyle={{
                                backgroundColor: 'rgba(1, 1, 1, 0.8)'
                              }}
                            >
                              <div
                                style={{
                                  height: '8px',
                                  width: '40px',
                                  backgroundColor: 'silver',
                                  margin: '0 auto',
                                  borderRadius: ' 10px',
                                  marginTop: '12px',
                                  marginBottom: '12px',
                                  cursor: 'move'
                                }}
                              />

                              <Stack flexDirection={'column'}>
                              {permission.includes('Edit') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        sheetRef.current.close();
                                        setdeduction(row);
                                        setDeductionModal(true);
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined" color="secondary"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify
                                        color={pallete.primary.main}
                                        icon="tabler:edit"
                                        sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                      />{' '}
                                      Edit
                                    </MenuItem>
                                  )}
                                  <Divider />
                                  {permission.includes('Delete') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        sheetRef.current.close();
                                        DeleteDeduction(id, index);
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined" color="secondary"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify
                                        color={pallete.primary.main}
                                        icon="ic:baseline-delete"
                                        sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                      />{' '}
                                      Delete
                                    </MenuItem>
                                  )}
                                  <Divider />
                              </Stack>
                            </ActionSheet>}
                                {/* Set align to "center" */}
                                {permission.includes('Edit') && (
                                  <>
                                    <Button
                                      onClick={(event) => {
                                        // Down_PDF(id);
                                        setdeduction(row);
                                        setDeductionModal(true);
                                      }}
                                      style={{ marginTop: 5 }}
                                      variant="outlined" color="secondary"
                                      component={RouterLink}
                                      to="#"
                                      startIcon={
                                        <Iconify color={pallete.grey[600]} icon="tabler:edit" />
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </>
                                )}
                                {permission.includes('Delete') && (
                                  <>
                                    <LoadingButton
                                      loading={deleting && selectedIndex == index && deleting}
                                      onClick={(event) => {
                                        DeleteDeduction(id, index);
                                        // setdeduction(row);
                                        // setDeductionModal(true);
                                      }}
                                      style={{ marginTop: 5, marginLeft: 10 }}
                                      variant="outlined" color="error"
                                      component={RouterLink}
                                      to="#"
                                      startIcon={
                                        <Iconify
                                          color={"red"}
                                          icon="ic:baseline-delete"
                                        />
                                      }
                                    >
                                      Delete
                                    </LoadingButton>
                                  </>
                                )}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={search} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              variant="outlined" color="secondary"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
