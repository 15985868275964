import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material ui
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Autocomplete,
  Grid
} from '@mui/material';

//
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { api } from 'src/Api';
import { useTranslation } from 'react-i18next';

ProductFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object
};

export default function ProductFilterSidebar({
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  list,
  onFilterChange,
  filteredDatanew,
  filter
}) {
  const { t } = useTranslation();
  const [modelList, setModelList] = useState([]);
  const [PartList, setPartList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [variantList, setvariantList] = useState([]);
  const [loading, setloading] = useState(false);

  const [startYear, setStartYear] = useState({name: ''});
  const [endYear, setEndYear] = useState({name: ''});

  //FORM SELECTED VALUES

  const [selectedYear, setselectedYear] = useState([]);
  const [selectedPart, setselectedPart] = useState([]);
  const [selectedMake, setselectedMake] = useState([]);
  const [selectedModel, setselectedModel] = useState([]);
  const [selectedColor, setselectedColor] = useState([]);
  const [selectedLocation, setselectedLocation] = useState([]);
  const [selectedVariant, setselectedVariant] = useState([]);

  useEffect(() => {
    getPartList();
    getMakeList();
    getModelList();
    getYearList();
    getColorList();
    getLocationList();
    getVariantList();
  }, []);

  // GET PART LIST
  function getPartList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_part, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          setPartList(result.data);
          if (filter.parts.length > 0) {
            let partsSelected = filter?.parts?.map((item, index) => ({
              id: index + 1,
              name: item
            }));
            setselectedPart(partsSelected);
          }
        } else {
          setPartList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET variant LIST
  function getVariantList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_variant, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          setvariantList(result.data);

          if (filter.variants.length > 0) {
            let variantSelected = filter?.variants?.map((item, index) => ({
              id: index + 1,
              name: item
            }));
            setselectedVariant(variantSelected);
          }
          // if (singleProduct.variant.length > 0 && result?.data.length > 0) {
          //   const filteredData = result?.data.filter(item => singleProduct.variant.includes(item.name));
          //   setselectedVariant(filteredData);
          // }
        } else {
          setvariantList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET MAKE LIST
  function getMakeList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_make, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          setMakeList(result.data);
          if (filter.make.length > 0) {
            let makeSelected = filter?.make?.map((item, index) => ({
              id: index + 1,
              name: item
            }));
            setselectedMake(makeSelected);
          }
        } else {
          setMakeList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET MODEL LIST
  function getModelList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_model, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          setModelList(result.data);
          if (filter.model.length > 0) {
            let transformedModels = filter?.model?.map((model, index) => ({
              id: index + 1,
              name: model
            }));
            setselectedModel(transformedModels);
          }
        } else {
          setModelList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET YEAR LIST
  function getYearList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_year, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          setYearList(result.data);

          if (filter.year.length > 0) {
            const transformedYears = filter?.year?.map((year, index) => ({
              id: index + 1,
              name: year
            }));
            setselectedYear(transformedYears);
          }
        } else {
          setYearList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET Color LIST
  function getColorList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_color, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          setColorList(result.data);
          if (filter.color.length > 0) {
            const transformedColor = filter?.color?.map((item, index) => ({
              id: index + 1,
              name: item
            }));
            setselectedYear(transformedColor);
          }
        } else {
          setColorList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET Location LIST
  function getLocationList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_location, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          setLocationList(result.data);
          if (filter.location.length > 0) {
            const transformedlocation = filter?.location?.map((item, index) => ({
              id: index + 1,
              name: item
            }));
            setselectedYear(transformedlocation);
          }
        } else {
          setLocationList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  const filterProducts = () => {
    let filtered = list;

    let newFilters = {};

    if (selectedPart.length > 0) {
      let selectedValues = selectedPart.map((option) => option.name);
      newFilters = { ...newFilters, parts: selectedValues };
    }
    if (selectedMake.length > 0) {
      let selectedValues = selectedMake.map((option) => option.name);
      newFilters = { ...newFilters, make: selectedValues };
    }
    if (selectedModel.length > 0) {
      let selectedValues = selectedModel.map((option) => option.name);
      newFilters = { ...newFilters, model: selectedValues };
    }
    // if (selectedYear.length > 0) {
    //   const selectedValues = selectedYear.map((option) => option.name);
    //   newFilters = { ...newFilters, year: selectedValues };
    // }
    if (startYear?.name) {
      newFilters = {...newFilters, start_year: startYear?.name}
    }
    if (endYear?.name) {
      newFilters = {...newFilters, end_year: endYear?.name}
    }
    if (selectedColor.length > 0) {
      const selectedValues = selectedColor.map((option) => option.name);
      newFilters = { ...newFilters, color: selectedValues };
    }

    if (selectedVariant.length > 0) {
      const selectedValues = selectedVariant.map((option) => option.name);
      newFilters = { ...newFilters, variant: selectedValues };
    }

    if (selectedLocation.length > 0) {
      const selectedValues = selectedLocation.map((option) => option.name);
      newFilters = { ...newFilters, location: selectedValues };
    }

    filteredDatanew(newFilters);
    onFilterChange(filtered);
    onCloseFilter();
  };

  const clearFilters = () => {
    setselectedYear([]);
    setselectedPart([]);
    setselectedMake([]);
    setselectedModel([]);
    setselectedColor([]);
    setselectedLocation([]);
    setselectedVariant([]);

    setStartYear({name: ''});
    setEndYear({name: ''});

    onCloseFilter();
    filteredDatanew([]);
    onFilterChange([]);
    filter([])
  }

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={onOpenFilter}
        sx={{
          fontSize: '14px',
          padding: '8px 16px',
          '& .MuiButton-endIcon': {
            margin: 0
          },
          '@media (max-width: 600px)': {
            fontSize: '12px',
            padding: '6px 10px'
          }
        }}
      >
        Filters
      </Button>

      <Drawer
        anchor="right"
        open={isOpenFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: '35%', border: 'none', overflow: 'hidden' }
        }}
        sx={{
          '@media (max-width: 960px)': {
            '& .MuiDrawer-paper': {
              width: '60%',
              maxWidth: '100%'
            }
          },
          '@media (max-width: 600px)': {
            '& .MuiDrawer-paper': {
              width: '80%',
              maxWidth: '100%'
            }
          }
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />
        
        <Scrollbar>
          <Grid container spacing={2} style={{ padding: '20px', justifyContent:'center', }}>
            <Grid item sm={11} xs={12}>
              <Autocomplete
                multiple
                fullWidth
                style={{ margin: 'auto', width: '100%', marginBottom: '20px' }}
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(PartList || [])]}
                getOptionLabel={(part) => part.name}
                onChange={(e, newValue) => {
                  setselectedPart(newValue);
                }}
                value={selectedPart}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Part Name" />}
              />
            </Grid>
            <Grid item sm={11} xs={12}>
              <Autocomplete
                multiple
                fullWidth
                style={{ margin: 'auto', width: '100%', marginBottom: '20px' }}
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(makeList || [])]}
                getOptionLabel={(make) => make.name}
                onChange={(e, newValue) => {
                  setselectedMake(newValue);
                }}
                value={selectedMake}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Make" />}
              />
            </Grid>
            <Grid item sm={11} xs={12}>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                id="combo-box-demo"
                style={{ margin: 'auto', width: '100%', marginBottom: '20px' }}
                SelectProps={{ native: false }}
                options={[...(modelList || [])]}
                getOptionLabel={(model) => model.name}
                onChange={(e, newValue) => {
                  setselectedModel(newValue);
                }}
                value={selectedModel}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Model" />}
              />
            </Grid>
            <Grid item sm={5.5} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                style={{
                  margin: 'auto',
                  width: '100%',
                  marginBottom: '20px'
                }}
                id="combo-box-demo1"
                SelectProps={{ native: false }}
                options={[...(yearList || [])]}
                getOptionLabel={(year) => year.name}
                onChange={(e, newValue) => {
                  setStartYear(newValue);
                }}
                value={startYear}
                renderInput={(params) => <TextField {...params} label="Start Year" />}
              />
            </Grid>
            <Grid item sm={5.5} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                style={{
                  margin: 'auto',
                  width: '100%',
                  marginBottom: '20px'
                }}
                id="combo-box-demo2"
                SelectProps={{ native: false }}
                options={[...(yearList || [])]}
                getOptionLabel={(year) => year.name}
                onChange={(e, newValue) => {
                  setEndYear(newValue);
                }}
                value={endYear}
                renderInput={(params) => <TextField {...params} label="End Year" />}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                style={{
                  margin: 'auto',
                  width: '100%',
                  marginBottom: '20px'
                }}
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(colorList || [])]}
                getOptionLabel={(color) => color.name}
                onChange={(e, newValue) => {
                  setselectedColor(newValue);
                }}
                value={selectedColor}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Color" />}
              />
            </Grid>

            <Grid item xs={12} sm={11}>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                style={{
                  margin: 'auto',
                  width: '100%',
                  marginBottom: '20px'
                }}
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(variantList || [])]}
                getOptionLabel={(variant) => variant.name}
                onChange={(e, newValue) => {
                  setselectedVariant(newValue);
                }}
                value={selectedVariant}
                renderInput={(params) => <TextField {...params} label="Variant" />}
              />
            </Grid>

            <Grid item xs={12} sm={11}>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                style={{ margin: 'auto', width: '100%', marginBottom: '20px' }}
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(locationList || [])]}
                getOptionLabel={(location) => location.name}
                onChange={(e, newValue) => {
                  setselectedLocation(newValue);
                }}
                value={selectedLocation}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Location" />}
              />
            </Grid>
          </Grid>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => filterProducts()}
          >
            Filter
          </Button>

          <Box style={{ marginTop: '20px' }}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              color='secondary'
              onClick={() => clearFilters()}
            >
              Clear Filters
            </Button>
          </Box>

        </Box>
      </Drawer>
    </>
  );
}
