import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Button, Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { api } from 'src/Api';
import { useTranslation } from 'react-i18next';
import { showFailedAlert, showSuccessAlert } from 'src/utils/toastifyAlert';

// ----------------------------------------------------------------------

export default function LocationAddModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();

  const [loading, setloading] = useState(false);
  const [locationList, setLocationList] = useState([]);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('Location name is required'))
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddLocation()
  });

  useEffect(() => {
    getLocationList();
  }, []);

  // GET Location LIST
  function getLocationList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_location, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        console.log('result::', result);

        if (result.success === true) {
          setLocationList(result.data);
        } else {
          setLocationList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  const AddLocation = () => {
    const company = JSON.parse(localStorage.getItem('company'));
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();

    data && formdata.append('id', data?.id);
    formdata.append('name', formik.values.name);
    formdata.append('company_id', company.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.save_location, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          showSuccessAlert(result.message);
          refresh();
        } else {
          showFailedAlert(result.message);
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  };


  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                type="string"
                label={'Name'}
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => AddLocation()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
