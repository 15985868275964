import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Box } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { RegisterForm } from '../sections/authentication/register';
import LOGO from '../asset/logo_black.png'


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Register() {
  return (
    <RootStyle title={`Register | ${process.env.REACT_APP_PROJECTNAME}`}>
      {/* <AuthLayout /> */}

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 4 }}>
            <Box className="Logo_image_center">
              <img src={LOGO} style={{width:"50%"}} />
            </Box>
            <Typography className="heading_center" sx={{ color: 'text.secondary',marginTop:"8px" }}>
              Enter your details below.
            </Typography>
          </Stack>

          <RegisterForm />
          <Typography className="heading_center" variant="body2" sx={{ mt: 2 }}>
            Already have an account? &nbsp;
            <Link to={'/login'} color="#01E268" fontWeight={"600"} component={RouterLink}>
            Login here
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
