import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  CardContent,
  Divider,
  TextField,
  Typography,
  Stack,
  Avatar,
  IconButton,
  InputAdornment,
  MenuItem
} from '@mui/material';
import Iconify from 'src/components/Iconify';

import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { api } from '../../../Api';
import GooglePlaces from 'src/components/GooglePlaces';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import { useFormik, Form, FormikProvider } from 'formik';

export default function AccountModal({ handleClose, data }) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));
  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    GetUsersRoles();
  }, []);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required(t('AddAgent.required.0')),
    lastName: Yup.string().required(t('AddAgent.required.1')),
    email: Yup.string().email(t('AddAgent.required.5')).required(t('AddAgent.required.2')),
    username: Yup.string().required(t('username required')),
    password: Yup.string().required(t('AddAgent.required.3')),
    confirmpassword: Yup.string().required(t('AddAgent.required.4')),
    role: Yup.number().required(t('AddAgent.required.7 required')),
    position: Yup.string().required(t('AddAgent.required.8 required')),
    dateHired: Yup.date().required(t('AddAgent.required.9 required')),
    dateResigned: Yup.date(),
    attachment: Yup.date()
  });

  const formik = useFormik({
    initialValues: {
      firstName: data?.firstname,
      lastName: data?.lastname,
      email: data?.email,
      username: data?.username,
      password: '',
      confirmpassword: '',
      role: data?.role || '',
      position: data?.position,
      dateHired: data?.dateHired,
      dateResigned: data?.dateResigned,
      attachment: data?.attachment
    },
    validationSchema: RegisterSchema,
    onSubmit: () => SaveProfile()
  });

  function SaveProfile() {
    console.log(data);

    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('id', Encryption(data?.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append('role', Encryption(data?.role.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'username',
      Encryption(formik.values.username, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'firstname',
      Encryption(formik.values.firstName, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'lastname',
      Encryption(formik.values.lastName, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('email', Encryption(formik.values.email, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'password',
      Encryption(formik.values.password, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'confirm_password',
      Encryption(formik.values.confirmpassword, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('position', Encryption(data?.position, process.env.REACT_APP_SECRET_KEY));
   formdata.append('date_hired', Encryption(data?.date_hired, process.env.REACT_APP_SECRET_KEY));
    if (file) {
      formdata.append('avatar', file.file);
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(`${api.update_profile}?id=${user.id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result, 'result update_profile');
        const response = JSON.parse(result);
        if (response.success === true) {
          console.log(response, 'result update_profile success');
          let decryptedData = response.data;
          localStorage.setItem('user', JSON.stringify(decryptedData));
          // setalert({ visible: true, message: response.message });
          handleClose();
        } else {
          const response = JSON.parse(result);
          setalert({ visible: true, message: response.message });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => setloading(false));
  }

  function GetUsersRoles(data) {
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_roles_names, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setRoles(result.data);
          // console.log('API call successful:', result);
        } else {
          console.error('API call failed:', result.error);
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  }

  const [file, setfile] = useState({ image: user.avatar, file: '' });
  const [objAfter, setobjAfter] = useState({
    firstName: user?.firstname,
    lastName: user?.lastname,
    email: user?.email,
    contact: user?.contact,
    address: user?.address,
    birhtday: user?.dob,
    file: false
  });


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const passwordGenerator = () => {
    const result = (Math.random() + 1).toString(36).substring(0);
    formik.values.password = result;
    formik.values.confirmpassword = result;
    formik.validateField();
  };
  const [alert, setalert] = useState({ visible: false, message: '' });
  return (
    <FormikProvider value={formik} borderRadius={2}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {t('EditProfile.name')}
        </Typography>
        <Stack
          mb={2}
          direction={{ xs: 'column' }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
          className="image_center"
        >
          <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {file && (
              <Avatar
                alt="Remy Sharp"
                src={objAfter?.file ? file.image : file.image + `?v='${Date.now()}`}
                sx={{ width: 80, height: 80 }}
                className="image_center"
              />
            )}
            <Button
              style={{
                width: !file ? 80 : null,
                height: !file ? 80 : 30,
                borderRadius: !file ? 40 : 20,
                marginTop: 10,
                marginBottom: 10,
                fontSize: 12
              }}
              variant={file ? 'outlined' : 'outlined'}
              component="label"
            >
              {t('EditProfile.buttons.0')}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => {
                  setobjAfter({
                    file: true
                  });
                  setfile({
                    image: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0]
                  });
                }}
              />
            </Button>
          </Box>
        </Stack>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('AddAgent.fields.0')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label={t('AddAgent.fields.1')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={t('AddAgent.fields.2')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label={t('username')}
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label={t('AddAgent.fields.3')}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              value={formik.values.confirmpassword}
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              label={t('AddAgent.fields.4')}
              {...getFieldProps('confirmpassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setshowConfirmPassword((prev) => !prev)}>
                      <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.confirmpassword && errors.confirmpassword)}
              helperText={touched.confirmpassword && errors.confirmpassword}
            />
          </Stack>
          <>
            {/* <TextField
            fullWidth
            type="file"
            label="Select File"
            onChange={(e) => setSelectedFile(e.target.files[0])}
          /> */}
            <Button
              sx={{
                marginRight: 1
              }}
              onClick={passwordGenerator}
              variant="outlined" color="secondary"
            >
              {t('AddAgent.buttons.0')}
            </Button>
          </>
          {alert && alert.visible && (
            <Alert
              style={{ fontSize: 12, marginBottom: 20 }}
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
            >
              {alert.message}
            </Alert>
          )}
          {/* {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
              style={{ fontSize: 12, marginBottom: 20 }}
            >
              {Object.entries(alert?.message).map(([field, message]) => (
                <p key={field}>
                  <b>{field}:</b> {message}
                </p>
              ))}
            </Alert>
          )} */}
          {invoiceData && (
            <Typography variant="h1" gutterBottom>
              working Fine
              {/* Invoice Data: {JSON.stringify(invoiceData)} */}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => SaveProfile()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
    // <Box p={1} mt={2}>
    //   <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
    //     {t('EditProfile.name')}
    //   </Typography>

    //   <CardContent>
    // <Stack
    //   mb={2}
    //   direction={{ xs: 'column' }}
    //   style={{
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'center'
    //   }}
    //   className="image_center"
    // >
    //   <Box
    //     style={{
    //       alignItems: 'center',
    //       display: 'flex',
    //       flexDirection: 'column'
    //     }}
    //   >
    //     {/* {file && (
    //       <Avatar
    //         alt="Remy Sharp"
    //         src={objAfter?.file ? file.image : file.image + `?v='${Date.now()}`}
    //         sx={{ width: 80, height: 80 }}
    //         className="image_center"
    //       />
    //     )} */}
    //     {/* <Button
    //       style={{
    //         width: !file ? 80 : null,
    //         height: !file ? 80 : 30,
    //         borderRadius: !file ? 40 : 20,
    //         marginTop: 10,
    //         marginBottom: 10,
    //         fontSize: 12
    //       }}
    //       variant={file ? 'outlined' : 'outlined'}
    //       component="label"
    //     >
    //       {t('EditProfile.buttons.0')}
    //       <input
    //         type="file"
    //         hidden
    //         accept="image/*"
    //         onChange={(e) => {
    //           setobjAfter({
    //             file: true
    //           });
    //           setfile({
    //             image: URL.createObjectURL(e.target.files[0]),
    //             file: e.target.files[0]
    //           });
    //         }}
    //       />
    //     </Button> */}
    //   </Box>
    // </Stack>
    //     <Grid container spacing={2} className="top_column">
    //       {/* FIRST NAME / LAST NAME */}
    //       {user?.role !== 4 && (
    //         <>
    //           <Grid item lg={6} sm={12} md={12}>
    //             <TextField
    //               id="outlined-error-helper-text"
    //               fullWidth
    //               label={t('EditProfile.fields.0')}
    //               type="text"
    //               value={firstName}
    //               onChange={(val) => {
    //                 setfirstName(val.target.value);
    //               }}
    //               variant="outlined" color="secondary"
    //               display="inline"
    //             />
    //           </Grid>
    //           <Grid item lg={6} sm={12} md={12}>
    //             <TextField
    //               id="outlined-error-helper-text"
    //               fullWidth
    //               label={t('EditProfile.fields.1')}
    //               value={lastName}
    //               onChange={(val) => {
    //                 setlastName(val.target.value);
    //               }}
    //               type="text"
    //               variant="outlined" color="secondary"
    //               display="inline"
    //             />
    //           </Grid>
    //         </>
    //       )}
    //       {user?.role === 4 && (
    //         <Grid item lg={6} sm={12} md={12}>
    //           <TextField
    //             id="outlined-error-helper-text"
    //             fullWidth
    //             label={t('EditProfile.fields.7')}
    //             value={firstName}
    //             onChange={(val) => {
    //               setfirstName(val.target.value);
    //             }}
    //             type="text"
    //             variant="outlined" color="secondary"
    //             display="inline"
    //           />
    //         </Grid>
    //       )}

    //       <Grid item lg={6} sm={12} md={12}>
    //         <TextField
    //           id="outlined-error-helper-text"
    //           fullWidth
    //           label={t('EditProfile.fields.2')}
    //           value={email}
    //           onChange={(val) => {
    //             setemail(val.target.value);
    //           }}
    //           type="text"
    //           variant="outlined" color="secondary"
    //           display="inline"
    //         />
    //       </Grid>
    //       <Grid item lg={6} sm={12} md={12}>
    //         <TextField
    //           id="outlined-error-helper-text"
    //           fullWidth
    //           label={t('EditProfile.fields.3')}
    //           name="contact"
    //           value={contact}
    //           onChange={(val) => {
    //             setcontact(val.target.value);
    //           }}
    //           type="tel"
    //           variant="outlined" color="secondary"
    //           display="inline"
    //         />
    //       </Grid>
    //       <Grid item lg={6} sm={12} md={12}>
    //         <GooglePlaces
    //           width={'100%'}
    //           onResult={(val) => {
    //             setaddress(val?.address);
    //             setstreetname(val.streetname);
    //             setstreetnumber(val.streetnumber);
    //             setsuburb(val.suburb);
    //             setpostal_code(val.postal_code);
    //             setstate(val.state);
    //             setcountry(val.country);
    //             setcity(val.city);
    //           }}
    //           address={user?.address}
    //           placeholder={t('EditProfile.fields.4')}
    //         />
    //       </Grid>
    //       {user?.role === 4 && (
    //         <Grid item lg={6} sm={12} md={12}>
    //           <TextField
    //             fullWidth
    //             id="outlined-select-currency"
    //             label={t('EditProfile.fields.6')}
    //             color={'primary'}
    //             value={agents}
    //             onChange={(e) => {
    //               setagents(e.target.value);
    //             }}
    //             type="number"
    //             inputProps={{
    //               min: 0,
    //               max: 10
    //             }}
    //           />
    //         </Grid>
    //       )}
    //       {user?.role !== 4 && (
    //         <Grid item lg={6} sm={12} md={12}>
    //           <TextField
    //             fullWidth
    //             id="outlined-select-currency"
    //             label={t('EditProfile.fields.5')}
    //             color={'primary'}
    //             value={birthday}
    //             onChange={(e) => {
    //               setbirthday(e.target.value);
    //             }}
    //             type="date"
    //             InputLabelProps={{ shrink: true }}
    //           />
    //         </Grid>
    //       )}
    //     </Grid>
    //   </CardContent>
    //   <Divider />
    // {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       justifyContent: 'flex-end',
    //       p: 2
    //     }}
    //   >
    //     <LoadingButton
    //       disabled={false}
    //       loading={loading}
    //       sx={{
    //         marginRight: 1
    //       }}
    //       onClick={() => {
    //         SaveProfile();
    //       }}
    //       color="primary"
    //       variant="contained"
    //     >
    //       {t('EditProfile.buttons.1')}
    //     </LoadingButton>
    //     <LoadingButton
    //       sx={{
    //         marginRight: 1
    //       }}
    //       onClick={handleClose}
    //       variant="outlined" color="secondary"
    //     >
    //       {t('EditProfile.buttons.2')}
    //     </LoadingButton>
    //   </Box>
    // </Box>
  );
}
