import * as React from 'react';
// import Box from '@mui/materia'
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Modal,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Box,
  Checkbox,
  Grid,
  Chip,
  useTheme,
  useMediaQuery,
  MenuItem,
  IconButton,
  Divider,
  Paper
} from '@mui/material';

// components

import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Encryption } from 'src/functions/function';
import ProductModal from 'src/sections/@dashboard/user/ProductModal';
import format from 'date-fns/format';
import ProductEditModal from 'src/sections/@dashboard/user/ProductEditModal';
import { ProductFilterSidebar } from 'src/sections/@dashboard/products';
import ProductBulkEditModal from 'src/sections/@dashboard/user/ProductBulkEditModal';
import Swal from 'sweetalert2';
import { showFailedAlert, showSuccessAlert } from 'src/utils/toastifyAlert';
import { isEmpty } from 'lodash';
import styles from './Invertoryy.module.css';
import moment from 'moment';
import { useRef } from 'react';
import MenuPopover from 'src/components/MenuPopover';
import ActionSheet from 'actionsheet-react';
import zIndex from '@mui/material/styles/zIndex';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Inventory() {
  const theme = useTheme();
  const [TABLE_HEAD, setTABLE_HEAD] = useState([
    { id: 'selectbox', label: '', alignRight: false },
    { id: 'id', label: 'SKU.', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'make', label: 'Make', alignRight: false },
    { id: 'model', label: 'Model', alignRight: false },
    { id: 'year', label: 'Year', alignRight: false },
    { id: 'color', label: 'Color', alignRight: false },
    { id: 'variant', label: 'Variant', alignRight: false },
    { id: 'location', label: 'Location', alignRight: false },
    { id: 'notes', label: 'Notes', alignRight: false },
    // { id: 'updated_at', label: 'Last Updated', alignRight: false }
  ]);

  const [permission, setpermission] = useState([]);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);
  const [showGridActons, setShowGridActons] = useState(false);
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [productModal, setProductModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [loadingTable, setloadingTable] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const [part, setpart] = useState(null);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [editProductData, seteditProductData] = useState();
  const [editData, setEditData] = useState(null);

  const handleToggleProductSelection = (productId) => {
    setSelectedProducts((prevSelectedProducts) => {
      const isSelected = prevSelectedProducts.some((product) => product.id === productId);
      if (isSelected) {
        return prevSelectedProducts.filter((product) => product.id !== productId);
      } else {
        const selectedProduct = list.find((product) => product.id === productId);
        return [...prevSelectedProducts, selectedProduct];
      }
    });
  };

  const [openPop, setOpenPop] = useState(false);
  const [openPopIndex, setOpenPopIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const sheetRef = useRef();

  // Function to handle bulk edit

  const handleBulkEdit = () => {
    // alert('hande')
    // console.log(editProductData);
    // alert('hande')
    const idList = selectedProducts.map((product) => product.id).join(',');
    // alert('idsss')
    // console.log(idList);
    // console.log(JSON.stringify(selectedProducts));
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    // const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('ids', idList);
    formdata.append('year', editProductData.year);
    formdata.append('color', editProductData.color);
    formdata.append('location', editProductData.location);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.saveProducts, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          showSuccessAlert(result.message);
          setIsBulkEditModalOpen(false);
          setSelectedProducts([]);
          getProducts();
        } else {
          console.log(result?.message, 'Error Register');
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
    setIsBulkEditModalOpen(false);
  };

  const handleOpenBulkEditModal = () => {
    setIsBulkEditModalOpen(true);
  };
  // Function to close bulk edit modal
  const handleCloseBulkEditModal = () => {
    setIsBulkEditModalOpen(false);
  };

  const handleOpenFilter = () => {
    setIsOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setIsOpenFilter(false);
  };

  const handleResetFilter = () => {
    // Implement logic to reset filter options
  };

  const requirement = ['View', 'Add', 'Edit', 'Delete'];

  const handleProductModalClose = () => setProductModal(false);
  const handleIsEditProductModalClose = () => setIsEditModalOpen(false);

  function FormatDate(date) {
    const myArray = date.split(' ');

    const formatedDate = format(Date.parse(myArray[0]), 'yyyy-MM-dd HH:mm:ss');
    return formatedDate;
  }

  function checkRender() {
    let check = 'Inventory';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    // console.log(localPerm);
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );

    const requirementGrid = ['Edit', 'Delete'];

    const checkGridActions = localPerm[0]?.permission_types?.filter((items) =>
      requirementGrid.includes(items.permission_type_name) ? true : false
    );

    if (checkGridActions && checkGridActions.length > 0) {
      setShowGridActons(true);
      setTABLE_HEAD((prevState) => [
        ...prevState,
        { id: 'actions', label: 'Actions', alignCenter: true }
      ]);
    } else {
      setShowGridActons(false);
    }
  }

  const refreshpaymentType = () => {
    setProductModal(false);
    setIsEditModalOpen(false);
    getProducts(page);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getProducts(page, searchText, isEmpty, newFilterData) {
    console.log(searchText + 'search text');
    setloading(true);
    setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    if (searchText) {
      formdata.append('search', Encryption(searchText, process.env.REACT_APP_SECRET_APP));
    } else if (search?.length > 0 && isEmpty == false) {
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));
    }

    if (newFilterData) {
      if (newFilterData.start_year) {
        formdata.append(
          'start_year',
          Encryption(newFilterData.start_year, process.env.REACT_APP_SECRET_APP)
        );
      }
      if (newFilterData.end_year) {
        formdata.append(
          'end_year',
          Encryption(newFilterData.end_year, process.env.REACT_APP_SECRET_APP)
        );
      }
      if (newFilterData.location) {
        formdata.append(
          'locations',
          Encryption(newFilterData.location, process.env.REACT_APP_SECRET_APP)
        );
      }
      if (newFilterData.color) {
        formdata.append(
          'colors',
          Encryption(newFilterData.color, process.env.REACT_APP_SECRET_APP)
        );
      }
      if (newFilterData.model) {
        formdata.append(
          'models',
          Encryption(newFilterData.model, process.env.REACT_APP_SECRET_APP)
        );
      }
      if (newFilterData.make) {
        formdata.append('makes', Encryption(newFilterData.make, process.env.REACT_APP_SECRET_APP));
      }

      if (newFilterData.parts) {
        formdata.append('parts', Encryption(newFilterData.parts, process.env.REACT_APP_SECRET_APP));
      }

      if (newFilterData.variant) {
        formdata.append(
          'variants',
          Encryption(newFilterData.variant, process.env.REACT_APP_SECRET_APP)
        );
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_products, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          // console.log('RESULT_--->', result);
          list.length = 0;
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  const handleEditModalOpen = (row) => {
    setselectedRow(row);
    setIsEditModalOpen(true);
  };

  function DeleteProducts(id, index) {
    setselectedIndex(index);
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this product?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'No, cancel',
      confirmButtonColor: pallete.primary.main,
      backdrop: false,
      reverseButtons: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${api.deleteProducts}`, requestOptions)
          .then((response) => response.text())
          .then((result_) => {
            const result = JSON.parse(result_);
            if (result.success === true) {
              showSuccessAlert(result.message);
              getProducts(page);
            } else {
              showFailedAlert(result.message);
            }
          })
          .catch((error) => {
            console.error('Error deleting product:', error);
          })
          .finally(() => {
            setdeleting(false);
            setselectedIndex(null);
          });
      } else {
        setdeleting(false);
        setselectedIndex(null);
      }
    });
  }

  function DeleteBulkProduct(id, index) {
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('inventory', Encryption(id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(`${api.delete_products_bulk}`, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          showSuccessAlert(result.message);
          setSelectedProducts([]);
          getProducts(page);
        }
      })
      .catch((error) => {
        console.log('error', error, 'Catch deleted error');
      })
      .finally(() => {
        setdeleting(false);
        setselectedIndex(null);
      });
  }

  const handleBulkDelete = () => {
    if (selectedProducts.length === 0) {
      Swal.fire('No Products Selected', 'Please select products to delete.', 'warning');
      return;
    }
    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete the selected products?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete',
      confirmButtonColor: pallete.primary.main,
      cancelButtonText: 'No, cancel',
      backdrop: false,
      reverseButtons: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed deletion, proceed with deletion
        showSuccessAlert(result.message);
        const selectedIds = selectedProducts.map((product) => product.id);
        DeleteBulkProduct(selectedIds);
      }
    });
  };

  useEffect(() => {
    checkRender();
    getProducts(page);
  }, []);

  const isUserNotFound = list.length === 0;

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getProducts(value, search, false, filter);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getProducts();
    }
  };

  const handleFilterChange = (filteredData) => {
    //  setFilteredProducts(filteredData);
  };

  const [filter, setfilter] = useState(null);

  const filteredDatanew = (data) => {
    setfilter(data);
    // setnewFilterData(data);
    // if (data.length > 0) {
    //   getProducts('', '', '', data);
    // }
    getProducts(1, search, isEmpty, data);
  };

  return (
    <Page title={`Inventory | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* INVENTORY MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={productModal}
          onClose={handleProductModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal" borderRadius={2}>
            <ProductModal
              handleClose={handleProductModalClose}
              refresh={refreshpaymentType}
              data={part}
              list={list}
            />
          </Box>
        </Modal>

        {isEditModalOpen && (
          <Modal
            style={{
              overflow: 'scroll',
              height: '100%',
              display: 'block'
            }}
            open={isEditModalOpen}
            onClose={handleIsEditProductModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="admin_modal" borderRadius={2}>
              <ProductEditModal
                handleClose={handleIsEditProductModalClose}
                data={part}
                singleProduct={selectedRow}
                refresh={refreshpaymentType}
              />
            </Box>
          </Modal>
        )}

        <Stack
          direction={{ md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={2}
        >
          <Typography variant="h3">Inventory</Typography>
          {permission.includes('Add') && (
            <>
              <Button
                onClick={() => {
                  setpart(null);
                  setProductModal(true);
                }}
                style={{ backgroundColor: '#78FFB6', color: '#000' }}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Inventory
              </Button>
            </>
          )}
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="center">
          {selectedProducts.length > 0 && (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  component={RouterLink}
                  color={"customGrey"}
                  to="#"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={handleOpenBulkEditModal}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  component={RouterLink}
                  to="#"
                  startIcon={<Iconify icon="ic:baseline-delete" />}
                  onClick={handleBulkDelete}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          )}
        </Stack>

        {/* Bulk Edit Modal */}
        {isBulkEditModalOpen && (
          <Modal
            style={{
              overflow: 'scroll',
              height: '100%',
              display: 'block'
            }}
            open={isBulkEditModalOpen}
            onClose={handleCloseBulkEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="admin_modal" borderRadius={2}>
              <ProductBulkEditModal
                handleClose={handleCloseBulkEditModal}
                handleBulkEdit={handleBulkEdit}
                editedData={seteditProductData}
              />
            </Box>
          </Modal>
        )}

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              style={{ zIndex: 999 }}
              placeholder="Search Inventory by (make, model, year, color)"
              width={'95%'}
              value={search}
              setFunction={(val) => {
                console.log(val.length, 'THIS IS IMPORTANT');
                setsearch(val);
                if (val != '') {
                  getProducts(page, val, false, filter);
                } else {
                  getProducts(page, val, true, filter);
                }
              }}
              // handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{
                width: '5%',
                marginLeft: '2.5%',
                background: '#EDEDED',
                color: '#000',
                boxShadow: `0px 26px 40px 0px rgba(188, 202, 255, 0.13)`
              }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                // setloadingTable(true);
                // getProducts();
                // setsearch('');
                getProducts(page, search, true, filter);
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>

            <LoadingButton
              style={{
                width: '9%',
                marginLeft: '2%',
                background: '#E3E3E3',
                color: '#000',
                boxShadow: `0px 26px 40px 0px rgba(188, 202, 255, 0.13)`
              }}
              variant="contained"
            >
              <ProductFilterSidebar
                isOpenFilter={isOpenFilter}
                onResetFilter={handleResetFilter}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
                list={list}
                onFilterChange={handleFilterChange}
                filteredDatanew={filteredDatanew}
                filter={filter}
                // setfilter={setfilter}
              />
            </LoadingButton>
          </Box>
            <Box style={{margin:"0px 20px 16px 20px",borderRadius:"12px"}}>
              <Scrollbar>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 800, overflow: 'visible', }}>
                    <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                    <TableBody sx={{ overflow: 'visible' }}>
                      {list.map((row, index) => {
                        const {
                          id,
                          sku,
                          name,
                          make,
                          model,
                          start_year,
                          end_year,
                          color,
                          variant,
                          location,
                          notes,
                          updated_at
                        } = row;

                        const generateChips = (data) => {
                          if (!data || data.trim() === '') {
                            return [
                              <Chip
                                style={{
                                  height: 18,
                                  padding: 0,
                                  fontSize: '0.60rem',
                                  fontWeight: 700
                                }}
                                key="N/A"
                                label="N/A"
                              />
                            ];
                          }

                          const dataArray = data.split(',');
                          return dataArray.map((item, index) => (
                            <Chip
                              style={{
                                height: 18,
                                padding: 0,
                                fontSize: '0.60rem',
                                fontWeight: 700
                              }}
                              key={item.trim()}
                              label={item.trim()}
                            />
                          ));
                        };

                        // Generate chips for make
                        const makeChips = generateChips(make);

                        // Generate chips for model
                        const modelChips = generateChips(model);

                      // Generate chips for year
                      const yearChips = generateChips(
                        start_year ? `${start_year} - ${end_year}` : null
                      );

                        // Generate chips for color
                        const colorChips = generateChips(color);

                        // Generate chips for variant
                        const variantChips = generateChips(variant);

                        // Generate chips for variant
                        const locationChips = generateChips(location);

                      return (
                        <TableRow
                          hover
                          style={{
                            cursor: 'pointer'
                          }}
                          key={id}
                          role="checkbox"
                          // className={styles.tableRow}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            // padding="normal"
                            style={
                              {
                                // width: 50
                              }
                            }
                          >
                            <Checkbox
                              checked={selectedProducts.some((product) => product.id === row.id)}
                              onChange={() => handleToggleProductSelection(row.id)}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            // padding="normal"
                            style={
                              {
                                // width: 50
                              }
                            }
                          >
                            {sku && sku ? sku : id}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              width: 150
                            }}
                          >
                            {name && name ? name : ''}
                          </TableCell>

                            <TableCell component="th" scope="row" padding="normal">
                              <Typography
                                style={{
                                  fontSize: 14,
                                  textAlign: 'justify'
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  {makeChips}
                                </div>
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 100
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {modelChips}
                              </div>
                              {/* {model && model ? model : ''} */}
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {yearChips || ''}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {colorChips || ''}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {variantChips || ''}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ textTransform: 'capitalize' }}>{locationChips || ''}</div>
                            </TableCell>
                            <TableCell>{notes || ''}</TableCell>
                            {/* <TableCell>
                              <div style={{ fontSize: 12 }}>{moment(updated_at).format('LLL')}</div>
                            </TableCell> */}

                            <TableCell align="center" style={{ flexDirection: 'column' }}>
                              {' '}
                              <IconButton
                                onClick={(e) => {
                                  setOpenPop(!openPop);
                                  setOpenPopIndex(id);
                                  setAnchorEl(e.currentTarget);
                                }}
                              >
                                <Iconify icon={'ri:more-fill'} />
                              </IconButton>
                              <MenuPopover
                                open={openPop && openPopIndex === id}
                                onClose={() => {
                                  setOpenPop(false);
                                  setAnchorEl(null);
                                }}
                                anchorEl={anchorEl}
                                sx={{ width: 220 }}
                              >
                                <Stack flexDirection={'column'}>
                                  {permission.includes('Edit') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setOpenPop(false);
                                        handleEditModalOpen(row);
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined"
                                      color="secondary"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify
                                        color={pallete.grey[600]}
                                        icon="tabler:edit"
                                         sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                      />{' '}
                                      Edit
                                    </MenuItem>
                                  )}
                                  {permission.includes('Delete') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setOpenPop(false);
                                        DeleteProducts(id, index);
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined"
                                      color="secondary"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      <Iconify
                                        color={"red"}
                                        icon="ic:baseline-delete"
                                         sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                      />{' '}
                                      Delete
                                    </MenuItem>
                                  )}
                                </Stack>
                              </MenuPopover>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {isUserNotFound && !loading && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={search} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  {loading && (
                    <Box
                      py={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: 1
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </TableContainer>
              </Scrollbar>
            </Box>
        </Card>

        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              variant="outlined"
              color="secondary"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
