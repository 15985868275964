import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Button, Box, Grid, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { api } from 'src/Api';
import { useTranslation } from 'react-i18next';
import { showFailedAlert, showSuccessAlert } from 'src/utils/toastifyAlert';

// ----------------------------------------------------------------------

export default function PartsModal({ handleClose, refresh, data ,list}) {
  const { t } = useTranslation();

  const [loading, setloading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [variantList, setvariantList] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState([])
  const [error, setError] = useState("")

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('Part name is required'))
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      variant: data?.variants || ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddParts()
  });

  useEffect(() => {
    getPartsList();
    getVariantList();
  }, []);

  // GET Location LIST
  function getPartsList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_part, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setLocationList(result.data);
        } else {
          setLocationList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  let modifiedFormikName = formik.values.name.trim().replace(/\s/g, '').toLowerCase();
  const [copy, setCopy] = useState([])
  useEffect(() => {
   if (modifiedFormikName === "airbag") {
    setvariantList([
      {name:"Left Knee Airbag",id:1},
      {name:"Right Knee Airbag",id:2},
      {name:"Left Roof Airbag",id:3},
      {name:"Right Roof Airbag",id:4},  
    ])
   }else{
   setvariantList(copy)
    }
  }, [formik.values.name])
  

  function getVariantList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_variant, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          if (data?.variants?.length > 0) {
            setSelectedVariant([{name:data?.variants}])
          }
          setvariantList(result?.data);
          setCopy(result?.data);

        } else {
          setvariantList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  const AddParts = () => {
    if (!data) {
      const dataFiltered = list?.filter((obj)=>{
        let trimmedName = obj.name.trim().replace(/\s/g, '').toLowerCase();
        return trimmedName.includes(modifiedFormikName);
      } )
      if (dataFiltered?.length > 0) {
        setError("Part is already added")
        return
      }
    }
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    const company = JSON.parse(localStorage.getItem('company'));

    var formdata = new FormData();

    data && formdata.append('id', data?.id);
    formdata.append('name', formik.values.name);
    formdata.append('variants', formik.values.variant);
    formdata.append('company_id', company.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.save_part, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          showSuccessAlert(result.message);
          refresh();
        } else {
          showFailedAlert(result.message);
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="string"
                label={'Name'}
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onFocus={()=>setError("")}
              />
              {error && (
                <p style={{fontSize:12,color:"red",marginTop:3,marginLeft:3}}>{error}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(variantList || [])]}
                getOptionLabel={(variant) => variant.name}
                onChange={(e, newValue) => {
                  const selectedValues = newValue.map((option) => option.name);
                  formik.setFieldValue('variant', selectedValues);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Variant" />}
              />
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => AddParts()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
