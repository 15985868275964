import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { api } from 'src/Api';
import { showSuccessAlert } from 'src/utils/toastifyAlert';
import * as Yup from 'yup';

export default function CompanyModal({ handleClose }) {
  const [file, setFile] = useState({ image: '', file: '' });
  const [objAfter, setObjAfter] = useState({
    file: false
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('UpdateCompany.validation.name')),
    phone: Yup.string().required(t('UpdateCompany.validation.phone')),
    address: Yup.string().required(t('UpdateCompany.validation.address'))
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      address: '',
    },
    validationSchemla: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const company = JSON.parse(localStorage.getItem('company'));
  useEffect(() => {
    if (company) {
      formik.setValues({
        name: company?.name,
        phone: company?.phone,
        address: company?.address
      });
      setFile({
        image: company?.logo ?  company?.logo : '',
        file: ""
      });
    }
  }, []);
  const [base64, setBase64] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const updateInfo = async () => {
    setSubmitting(true);
    const company = JSON.parse(localStorage.getItem('company'))
    let body = {
      name: formik.values.name || '',
      phone: formik.values.phone || '',
      address: formik.values.address || '',
    };
    if (base64) {
      body = {
        ...body,
        logo: base64
      }
    }
    const formData = new FormData();
    formData.append('name', formik.values.name);
    formData.append('phone', formik.values.phone);
    formData.append('address', formik.values.address);
    if (file.file) {
      formData.append('logo', file.file);
    }

    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      // body: JSON.stringify(body),
      body: formData,
      redirect: 'follow'
    };
    await fetch(`${api.update_company}/${company.id}`, requestOptions)
    .then((response) => response.text())
    .then(async (result) => {
        showSuccessAlert("Company Updated Successfully")
        await  getCompany();
        handleClose();
    }).catch((error) => {})
    .finally(() => {
      setSubmitting(false);
    });
  }

  const getCompany = async () => {
    const companyMain = JSON.parse(localStorage.getItem('company'))
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    const getRequestOption  = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
    fetch(`${api.get_company}/${companyMain.id}`, getRequestOption)
    .then((response) => response.text())
    .then((result) => {
      const company = JSON.parse(result);
      const companyData = {
        name: company.details.name,
        phone: company.details.phone,
        address: company.details.address,
        logo:company.details.logo ? 'https://api.aiautoparts.net/public/storage/' + company.details.logo : null,
        id: companyMain.id
      }
      localStorage.setItem('company', JSON.stringify(companyData));
      if ('https://api.aiautoparts.net/public/storage/' + company.details.logo !== file.image) {
        window.location.reload();
      }
    }).catch((error) => console.log('error', error));
  }
  
  const handleFileInputChange = (fileItem) => {
    const file = fileItem;
    const reader = new FileReader();

    reader.onload = () => {
      const base64 = reader.result;
      setBase64(base64);
    };

    reader.readAsDataURL(file);
  };
  console.log(file, 'company?.logo')
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
      <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {t('UpdateCompany.name')}
        </Typography>
        <Stack
          mb={2}
          direction={{ xs: 'column' }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
          className="image_center"
        >
         <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
          {
            company?.logo && !objAfter?.file && (
              <Avatar
                alt="Remy Sharp"
                src={file.image}
                sx={{ width: 80, height: 80 }}
                className="image_center"
              />
            )
          }
            {file && objAfter?.file && (
              <Avatar
                alt="Remy Sharp"
                src={objAfter?.file ? file.image : 'R'}
                sx={{ width: 80, height: 80 }}
                className="image_center"
              />
            )}
            <Button
              style={{
                width: !file ? 80 : null,
                height: !file ? 80 : 30,
                borderRadius: !file ? 40 : 20,
                marginTop: 10,
                marginBottom: 10,
                fontSize: 12
              }}
              variant={file ? 'outlined' : 'outlined'}
              component="label"
            >
              {t('EditProfile.buttons.0')}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => {
                  setObjAfter({
                    file: true
                  });
                  setFile({
                    image: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0]
                  });
                  handleFileInputChange(e.target.files[0])
                }}
              />
            </Button>
          </Box>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column' }} spacing={2}>
            <TextField
              fullWidth
              label={t('UpdateCompany.fields.0')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label={t('UpdateCompany.fields.1')}
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            <TextField
              fullWidth
              label={t('UpdateCompany.fields.2')}
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
          </Stack>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              // onClick={() => SaveProfile()}
              onClick={updateInfo}
              variant="contained"
              loading={submitting}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button 
            onClick={handleClose} 
            variant="outlined" color="secondary">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
          </Stack>
      </Form>
    </FormikProvider>
  );
}
