import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// material

import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Checkbox,
  Typography,
  Alert,
  MenuItem,
  Autocomplete,
  Grid,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import DropDown from 'src/layouts/dashboard/DropDown';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import { showFailedAlert, showSuccessAlert } from 'src/utils/toastifyAlert';

// ----------------------------------------------------------------------

export default function ProductEditModal({ handleClose, refresh, data, singleProduct }) {
  console.log(singleProduct);
  const { t } = useTranslation();

  const [checkBox, setcheckBox] = useState(false);
  const [checkerror, setcheckerror] = useState(false);
  const [loading, setloading] = useState(false);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [modelList, setModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [variantList, setvariantList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);

  //FORM SELECTED VALUES

  const [startYear, setStartYear] = useState(null);
  const [lastYear, setLastYear] = useState(null);
  const [selectedPart, setselectedPart] = useState(null);
  const [selectedMake, setselectedMake] = useState([]);
  const [selectedModel, setselectedModel] = useState([]);
  const [selectedColor, setselectedColor] = useState([]);
  const [selectedLocation, setselectedLocation] = useState(null);
  const [selectedVariant, setselectedVariant] = useState([]);
  const [airBagVariant, setAirBagVariant] = useState([
    { name: 'Left Knee Airbag', id: 1 },
    { name: 'Right Knee Airbag', id: 2 },
    { name: 'Left Roof Airbag', id: 3 },
    { name: 'Right Roof Airbag', id: 4 }
  ]);
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('Part name is required')),
    make: Yup.array().required(t('Make is Required')),
    year: Yup.array().required(t('Year is Required')),
    model: Yup.array().required(t('Model is Required')),
    location: Yup.string().required(t('Location is Required'))
    // variant: Yup.array().nullable().required(t('Variant is Required')),
  });

  const formik = useFormik({
    initialValues: {
      name: singleProduct?.name || '',
      notes: singleProduct?.notes || '',
      make: singleProduct?.make || [],
      model: singleProduct?.model || [],
      startYear: singleProduct?.start_year || '',
      lastYear: singleProduct?.end_year || '',
      location: singleProduct?.location || '',
      color: singleProduct?.color || [],
      variant: singleProduct?.name || []
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddProduct()
  });

  useEffect(() => {
    if (checkBox === true) {
      setcheckerror(false);
    }
  }, [checkBox]);

  useEffect(() => {
    if (formik.values.make) {
      getModelList(formik.values.make);
    } else {
      getModelList(singleProduct?.make);
    }
  }, [formik.values.make]);

  useEffect(() => {
    getMakeList();
  }, []);

  useEffect(() => {
    if (formik.values.variant) {
      getVariantList(formik.values.variant);
    } else {
      getVariantList(singleProduct?.variant);
    }
  }, []);

  useEffect(() => {
    getColorList();
  }, []);

  useEffect(() => {
    getYearList();
  }, []);

  useEffect(() => {
    getLocationList();
  }, []);

  useEffect(() => {
    getInvoiceList();
  }, []);

  // GET variant LIST
  function getVariantList(variantList) {
    setloading(true);
    const name = variantList.replace(/\s/g, '').toLowerCase();
    if (name == 'airbag') {
      const filteredData = airBagVariant.filter((item) =>
        singleProduct.variant.includes(item.name)
      );
      setselectedVariant(filteredData);
      setvariantList(airBagVariant);
      setloading(false);
      return;
    }
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    if (variantList) {
      formdata.append('variant_name', variantList);
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_variant, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setvariantList(result?.data);
          if (singleProduct?.variant?.length > 0 && result?.data.length > 0) {
            const filteredData = result?.data.filter((item) =>
              singleProduct?.variant.includes(item.name)
            );
            setselectedVariant(filteredData);
          }
        } else {
          setvariantList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET MODEL LIST
  function getModelList(makeList) {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    if (makeList) {
      formdata.append('make_name', makeList);
    }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_model, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setModelList(result?.data);
          if (singleProduct?.model?.length > 0 && result?.data?.length > 0) {
            const filteredData = result?.data.filter((item) =>
              singleProduct?.model.includes(item.name)
            );
            setselectedModel(filteredData);
          }
        } else {
          setModelList([]);
        }
      })
      .finally(() => {
        setloading(false);
      });
  }

  // GET MAKE LIST
  function getMakeList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_make, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setMakeList(result?.data);
          if (singleProduct?.make?.length > 0 && result?.data?.length > 0) {
            const filteredData = result?.data.filter((item) =>
              singleProduct?.make.includes(item.name)
            );
            setselectedMake(filteredData);
          }
        } else {
          setMakeList([]);
        }
      })
      .catch((error) => console.error(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }
  // GET YEAR LIST
  function getYearList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_year, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setYearList(result?.data);
          console.log(singleProduct, 'singleProduct');
          if (singleProduct?.start_year?.length > 0 && result?.data?.length > 0) {
            setStartYear({ name: singleProduct?.start_year });
            setLastYear({ name: singleProduct?.end_year });
          }
        } else {
          setYearList([]);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setloading(false);
      });
  }

  // GET Color LIST
  function getColorList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_color, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setColorList(result?.data);
          if (singleProduct?.color?.length > 0 && result?.data?.length > 0) {
            const filteredData = result?.data.filter((item) =>
              singleProduct?.color.includes(item.name)
            );
            setselectedColor(filteredData);
          }
        } else {
          setColorList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET Location LIST
  function getLocationList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_location, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setLocationList(result?.data);
          if (singleProduct?.location != null && result?.data?.length > 0) {
            const filteredData = result?.data.filter(
              (item) => singleProduct?.location == item?.name
            );
            setselectedLocation(filteredData[0]);
            console.log(filteredData[0]);
          }
        } else {
          setLocationList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET INVOICE LIST
  function getInvoiceList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_part, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);
        if (result?.success === true) {
          // console.log(singleProduct.name , 'ajajajj');
          setInvoiceList(result?.data);
          // window.alert(singleProduct.name)
          if (singleProduct?.name != null && result?.data?.length > 0) {
            const filteredData = result?.data.filter(
              (item) => singleProduct?.name?.toLowerCase() == item?.name.toLowerCase()
            );
            setselectedPart(filteredData[0]);
          }
        } else {
          setInvoiceList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  const AddProduct = () => {
    console.log('cahalaaaa');
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    singleProduct && formdata.append('company_id', singleProduct?.id);
    formdata.append('name', formik.values.name);
    formdata.append('notes', formik.values.notes);
    const joinArrayValues = (fieldName) =>
      Array.isArray(formik.values[fieldName])
        ? formik.values[fieldName].join(',')
        : formik.values[fieldName];

    const makeNames = selectedMake.map((make) => make.name).join(',');
    const modalNames = selectedModel.map((modal) => modal.name).join(',');
    const colorNames = selectedColor.map((color) => color.name).join(',');
    const variantNames = selectedVariant.map((variant) => variant.name).join(',');

    // console.log(selectedPart);

    formdata.append('make', makeNames);
    formdata.append('name', selectedPart?.name);
    formdata.append('model', modalNames);
    formdata.append('start_year', startYear?.name);
    formdata.append('last_year', lastYear?.name);
    formdata.append('color', colorNames);
    formdata.append('location', selectedLocation?.name);
    formdata.append('variant', variantNames);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.saveProducts, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result?.success === true) {
          showSuccessAlert(result?.message);
          refresh();
        } else {
          console.log('hamzaaaaaaaaaaaaaaaaaaa' + result?.message);
          setalert({ visible: true, message: result?.message });
          showFailedAlert(result?.message);
          // showFailedAlert(result.message);
          // setalert({ visible: true, message: result.message });
          // console.log(result?.message, 'Error Register');
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  };

  const handleNameChange = (event) => {
    formik.setValues({
      name: event.target.value
    });
  };
  const handleMakeChange = (event) => {
    // getModelList(event.target.value)
    formik.setValues({
      make: event.target.value
    });
  };
  const handleModelChange = (event) => {
    formik.setValues({
      model: event.target.value
    });
  };
  const handleColorChange = (event) => {
    formik.setValues({
      color: event.target.value
    });
  };
  const handleLocationChange = (event) => {
    formik.setValues({
      location: event.target.value
    });
  };
  const handleYearChange = (event) => {
    formik.setValues({
      year: event.target.value
    });
  };

  console.log('selectedYear', startYear, lastYear);

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{ padding: 20, maxHeight: '70vh', overflowY: 'auto' }}
      >
        {/* <Stack spacing={3}> */}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                id="grouped-demo"
                options={invoiceList.sort((a, b) => -b.name.localeCompare(a.name))}
                // groupBy={(option) => option.name}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  setselectedPart(newValue);
                  getVariantList(newValue.name);
                  // formik.setFieldValue('name', newValue.name);
                }}
                value={selectedPart}
                fullWidth
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Name" fullWidth />}
              />
              {/* <InputLabel id="demo-simple-select-label">Name</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Name"
                onChange={handleNameChange}
                {...getFieldProps('name')}
              >
                {invoiceList.map((item) => {
                  return (
                    <MenuItem value={item.name} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(makeList || [])]}
                getOptionLabel={(make) => make.name}
                onChange={(e, newValue) => {
                  setselectedMake(newValue);
                  // getModelList(newValue);

                  const selectedValues = newValue.map((option) => option.name);
                  // formik.setFieldValue('make', selectedValues);
                  getModelList(selectedValues);
                }}
                value={selectedMake}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Make"
                    // {...getFieldProps('make')}
                    error={Boolean(touched.make && errors.make)}
                    helperText={touched.make && errors.make}
                  />
                )}
              />
              {/* <InputLabel id="demo-simple-select-label">Make</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Make"
                onChange={handleMakeChange}
                {...getFieldProps('make')}
              >
                {makeList.map((item) => {
                  return (
                    <MenuItem value={item.name} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(modelList || [])]}
                getOptionLabel={(model) => model.name}
                onChange={(e, newValue) => {
                  setselectedModel(newValue);
                  // const selectedValues = newValue.map((option) => option.name);
                  // getModelList(selectedValues);
                  // formik.setFieldValue('model', selectedValues);
                }}
                value={selectedModel}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Model"
                    // {...getFieldProps('model')}
                    error={Boolean(touched.model && errors.model)}
                    helperText={touched.model && errors.model}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(colorList || [])]}
                getOptionLabel={(color) => color.name}
                onChange={(e, newValue) => {
                  setselectedColor(newValue);
                  // const selectedValues = newValue.map((option) => option.name);
                  // formik.setFieldValue('color', selectedValues);
                }}
                value={selectedColor}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Color" />}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(yearList || [])]}
                getOptionLabel={(startYear) => startYear.name}
                onChange={(e, newValue) => {
                  setStartYear(newValue);
                }}
                value={startYear ? startYear : { name: '' }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Start Year"
                    // {...getFieldProps('year')}
                    error={Boolean(touched.startYear && errors.startYear)}
                    helperText={touched.startYear && errors.startYear}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(yearList || [])]}
                getOptionLabel={(end_year) => end_year.name}
                onChange={(e, newValue) => {
                  setLastYear(newValue);
                }}
                value={lastYear ? lastYear : { name: '' }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="End Year"
                    // {...getFieldProps('year')}
                    error={Boolean(touched.lastYear && errors.lastYear)}
                    helperText={touched.lastYear && errors.lastYear}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                id="combo-box-demo"
                SelectProps={{ native: false }}
                options={[...(variantList || [])]}
                getOptionLabel={(color) => color.name}
                onChange={(e, newValue) => {
                  setselectedVariant(newValue);
                }}
                value={selectedVariant}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Variant"
                    error={Boolean(touched.variant && errors.variant)}
                    helperText={touched.variant && errors.variant}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="grouped-demo"
                options={locationList || ''}
                // groupBy={(option) => option.name}
                getOptionLabel={(location) =>
                  location.name.charAt(0).toUpperCase() + location.name.slice(1)
                }
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setselectedLocation(newValue);
                  // formik.setFieldValue('name', newValue.name);
                }}
                value={selectedLocation}
                fullWidth
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id} style={{ textTransform: 'capitalize' }}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Location" fullWidth />}
              />
              {/* <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Location"
                onChange={handleLocationChange}
                {...getFieldProps('location')}
              >
                {locationList.map((item) => {
                  return (
                    <MenuItem value={item.name} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label={'Notes (Optional)'}
              type="string"
              multiline={true}
              rows={8}
              {...getFieldProps('notes')}
              error={Boolean(touched.notes && errors.notes)}
              helperText={touched.notes && errors.notes}
            />
          </Grid>
        </Grid>
        <Box mt={2} />
        {alert.visible && (
          <Alert
            onClose={() => {
              setalert({ visible: false, message: '' });
            }}
            severity="error"
            style={{ fontSize: 12, marginBottom: 20 }}
          >
            {Object.entries(alert?.message).map(([field, message]) => (
              <p key={field}>
                <b>{field}:</b> {message}
              </p>
            ))}
          </Alert>
        )}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: 10
          }}
        >
          <LoadingButton
            sx={{ marginRight: 2 }}
            size="large"
            onClick={() => AddProduct()}
            variant="contained"
            loading={loading}
          >
            {t('AddAgent.buttons.1')}
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            {t('AddAgent.buttons.2')}
          </Button>
        </Box>
        {/* </Stack> */}
      </Form>
    </FormikProvider>
  );
}
