import * as React from 'react';
// import Box from '@mui/materia'
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Modal,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Box,
  Chip,
  useTheme,
  useMediaQuery,
  MenuItem,
  Divider,
  IconButton,
  Paper
} from '@mui/material';

// components

import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';
import LocationAddModal from 'src/sections/@dashboard/user/LocationAddModal';
import Swal from 'sweetalert2';
import { showSuccessAlert } from 'src/utils/toastifyAlert';
import PartsModal from 'src/sections/@dashboard/user/PartsModal';
import { useRef } from 'react';
import ActionSheet from 'actionsheet-react';
import MenuPopover from 'src/components/MenuPopover';

export default function Parts() {
  const [TABLE_HEAD, setTABLE_HEAD] = useState([
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'variants', label: 'Variants', alignRight: false }
  ]);
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [locationModal, setLocationModal] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const [permission, setpermission] = useState([]);
  const requirement = ['View', 'Add', 'Edit', 'Delete'];
  const [showGridActons, setShowGridActons] = useState(false);
  const [part, setpart] = useState(null);

  const theme = useTheme();
  const [openPop, setOpenPop] = useState(false);
  const [openPopIndex, setOpenPopIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const sheetRef = useRef();

  function checkRender() {
    let check = 'Parts';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    // console.log(localPerm);
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );
    const requirementGrid = ['Edit', 'Delete'];
    const checkGridActions = localPerm[0]?.permission_types?.filter((items) =>
      requirementGrid.includes(items.permission_type_name) ? true : false
    );
    if (checkGridActions && checkGridActions.length > 0) {
      setShowGridActons(true);
      setTABLE_HEAD((prevState) => [
        ...prevState,
        { id: 'actions', label: 'Actions', alignRight: true }
      ]);
    } else {
      setShowGridActons(false);
    }
  }
  const handleProductModalClose = () => setLocationModal(false);
  const refreshpaymentType = () => {
    setLocationModal(false);
    getParts();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getParts(page) {
    setloading(true);
    setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_part, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloadingTable(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          list.length = 0;
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  function DeleteParts(id, index) {
    setselectedIndex(index);
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to delete this location?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'No, cancel',
      reverseButtons: true,
      confirmButtonColor: pallete.primary.main,
      backdrop: false,
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${api.delete_part}`, requestOptions)
          .then((response) => response.text())
          .then((result_) => {
            const result = JSON.parse(result_);
            if (result.success === true) {
              showSuccessAlert(result.message);
              getParts(page);
            }
          })
          .catch((error) => {
            console.error('Error deleting location:', error);
          })
          .finally(() => {
            setdeleting(false);
            setselectedIndex(null);
          });
      } else {
        setdeleting(false);
        setselectedIndex(null);
      }
    });
  }
  useEffect(() => {
    checkRender();
    getParts();
  }, []);

  const isUserNotFound = list.length === 0;
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getParts(value);
  };

  const handleKeyPress = (event) => {
    // console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getParts();
    }
  };

  return (
    <Page title={`Parts | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* INVENTORY MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={locationModal}
          onClose={handleProductModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal" borderRadius={2}>
            <PartsModal
              handleClose={handleProductModalClose}
              refresh={refreshpaymentType}
              data={part}
              list={list}
            />
          </Box>
        </Modal>

        <Stack
          direction={{ md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={2}
        >
          <Typography variant="h3">Parts</Typography>
          {permission.includes('Add') && (
            <>
              <Button
                onClick={() => {
                  setpart(null);
                  setLocationModal(true);
                }}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Parts
              </Button>
            </>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder="Search Parts"
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{
                width: '5%',
                marginLeft: '2.5%',
                background: '#EDEDED',
                color: '#000',
                boxShadow: `0px 26px 40px 0px rgba(188, 202, 255, 0.13)`
              }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getParts();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Box style={{margin:"0px 20px 16px 20px"}}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800, overflow: 'visible' }}>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                  <TableBody sx={{ overflow: 'visible' }}>
                    {list.map((row, index) => {
                      const { id, name, variants } = row;
                      const generateChips = (data) => {
                        if (!data || data.trim() === '') {
                          return [
                            <Chip
                              style={{
                                height: 18,
                                padding: 0,
                                fontSize: '0.60rem',
                                fontWeight: 700
                              }}
                              key="N/A"
                              label="N/A"
                            />
                          ];
                        }

                        const dataArray = data.split(',');
                        return dataArray.map((item) => (
                          <Chip
                            style={{
                              height: 18,
                              padding: 0,
                              fontSize: '0.60rem',
                              fontWeight: 700
                            }}
                            key={item.trim()}
                            label={item.trim()}
                          />
                        ));
                      };

                      // Generate chips for make
                      const variantsChips = generateChips(variants);

                      return (
                        <TableRow
                          hover
                          style={{
                            cursor: 'pointer'
                            // Remove fixed height style
                          }}
                          key={id}
                          role="checkbox"
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={
                              {
                                // width: 150
                              }
                            }
                          >
                            {name && name ? name : ''}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={
                              {
                                // width: 150
                              }
                            }
                          >
                            {variantsChips}
                            {/* {variants && variants ? variants : 'N/A'}
                             */}
                          </TableCell>

                          <TableCell align="right" style={{ flexDirection: 'column' }}>
                            {' '}
                            <IconButton
                              onClick={(e) => {
                                setOpenPop(!openPop);
                                setOpenPopIndex(id);
                                setAnchorEl(e.currentTarget);
                              }}
                            >
                              <Iconify icon={'ri:more-fill'} />
                            </IconButton>
                            <MenuPopover
                              open={openPop && openPopIndex === id}
                              onClose={() => {
                                setOpenPop(false);
                                setAnchorEl(null);
                              }}
                              anchorEl={anchorEl}
                              sx={{ width: 220 }}
                            >
                              <Stack flexDirection={'column'}>
                                {permission.includes('Edit') && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setOpenPop(false);
                                      setpart(row);
                                      setLocationModal(true);
                                    }}
                                    style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                    variant="outlined"
                                    color="secondary"
                                    component={RouterLink}
                                    to="#"
                                  >
                                    <Iconify
                                      color={pallete.grey[600]}
                                      icon="tabler:edit"
                                      sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                    />{' '}
                                    Edit
                                  </MenuItem>
                                )}
                                {permission.includes('Delete') && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setOpenPop(false);
                                      DeleteParts(id, index);
                                    }}
                                    style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                    variant="outlined"
                                    color="secondary"
                                    component={RouterLink}
                                    to="#"
                                  >
                                    <Iconify
                                      color={"red"}
                                      icon="ic:baseline-delete"
                                      sx={{ mr: 1 }} style={{fontSize:"20px" }}
                                    />{' '}
                                    Delete
                                  </MenuItem>
                                )}
                              </Stack>
                            </MenuPopover>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={search} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              variant="outlined"
              color="secondary"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
