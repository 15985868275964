import React, { useEffect } from 'react';

const LanguagePicker = () => {
  return (
    <>
      <div id="google_translate_element"></div>
    </>
  );
};

export default LanguagePicker;
