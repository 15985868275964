// routes
import Router from './routes';
import { useEffect } from 'react';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { Snackbar } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ToastContainer } from 'react-toastify';

// ----------------------------------------------------------------------
// NEW CODE

export default function App() {
  return (
    <ThemeConfig>
      <SnackbarProvider maxSnack={10}>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
        <ToastContainer />
      </SnackbarProvider>
    </ThemeConfig>
  );
}
