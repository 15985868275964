function Encryption(text, key) {
  if (process.env.REACT_APP_ENCRYPTION == 'ON') {
    let encrypted = btoa(key) + '|' + btoa(unescape(encodeURIComponent(text))) + '|' + btoa(key);
    return btoa(encrypted);
  } else {
    return text;
  }
}

function Decryption(cipher) {
  if (process.env.REACT_APP_ENCRYPTION == 'ON') {
    let cip = atob(cipher);
    let encryptedText = cip.split('|');
    return decodeURIComponent(escape(atob(encryptedText[1])));
  } else {
    return cipher;
  }
}

export { Encryption, Decryption };
