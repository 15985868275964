import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material

import {
  Stack,
  TextField,
  Button,
  Box,
  Typography,
  Alert,
  Autocomplete,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Modal,
  Container,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { LoadingButton } from '@mui/lab';
// component
import { api } from 'src/Api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { showFailedAlert, showSuccessAlert } from 'src/utils/toastifyAlert';
// ----------------------------------------------------------------------

export default function EditInvoice(props) {
  const { handleClose, refresh, data } = props;
  const { t } = useTranslation();

  const [loading, setloading] = useState(false);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [open, setOpen] = useState(false);
  const [pdfViewer, setPdfViewer] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [amount, setUnitPrice] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [rows, setRows] = useState([]);
  const [displayPaymentFields, setDisplayPaymentFields] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [getPayment, setGetPayment] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const AddProduct = () => {
    const newRow = {
      type: 'Fixed',
      name: selectedProduct ? selectedProduct.name : '',
      year: selectedProduct ? selectedProduct.year : '',
      make: selectedProduct ? selectedProduct.make : '',
      model: selectedProduct ? selectedProduct.model : '',
      color: selectedProduct ? selectedProduct.color : '',
      quantity: parseInt(quantity),
      amount: parseInt(amount),
      total: parseInt(quantity) * parseInt(amount)
    };

    setRows([...rows, newRow]);
    setSelectedProduct(null);
    setQuantity('');
    setUnitPrice('');
  };

  const deleteProduct = (index) => {
    let productList = [...rows];
    productList.splice(index, 1);
    setRows(productList);
    if (productList.length === 0) {
      setIsFormValid(false);
    }
  };

  const calculateSubtotal = () => {
    return rows.reduce((acc, row) => acc + row.total, 0);
  };

  const calculateTaxAmount = (subtotal, taxpercent) => {
    let taxAmount = subtotal * (taxpercent / 100);
    return taxAmount.toFixed(2);
  };

  const calculateGrandTotal = (subtotal, taxamount) => {
    const subtotalNumber = Number(subtotal);
    const taxAmountNumber = Number(taxamount);
    const grandTotal = subtotalNumber + taxAmountNumber;
    return grandTotal.toFixed(2);
  };

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('customer name is required')),
    email: Yup.string().required(t('customer email is required')),
    contact: Yup.string().required(t('customer contact is required')),
    quantity: Yup.number()
      .required('Quantity is required')
      .positive('Quantity must be positive')
      .integer('Quantity must be an integer'),
    amount: Yup.number().required('Unit price is required').positive('Unit price must be positive'),
    taxpercent: Yup.number()
      .required('Tax percent is required')
      .positive('Tax percent must be positive')
  });

  const formik = useFormik({
    initialValues: {
      name: location.state.row?.name || '',
      email: location.state.row?.email || '',
      contact: location.state.row?.contact || '',
      month: location.state.row?.month || '',
      invoice_details: location.state.row?.invoice_details || [],
      notes: location.state.row?.notes || '',
      taxpercent: location.state.row?.taxpercent || '',
      payment_date: location.state.row?.payment_date || '',
      payment_type: location.state.row?.payment_type || '',
      amount: location.state.row?.amount || 0
    },
    validationSchema: RegisterSchema
  });

  const AddInvoice = () => {
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    const subtotal = calculateSubtotal();
    const taxamount = calculateTaxAmount(subtotal, formik.values.taxpercent);
    const total = calculateGrandTotal(subtotal, taxamount);

    var formdata = new FormData();

    const dates = moment().format('yyyy-MM-DD');
    const taxPercentValue = formik.values.taxpercent || 0;
    formdata.append('employee_id', user.id);
    formdata.append('id', location.state.row.id);
    formdata.append('name', formik.values.name);
    formdata.append('email', formik.values.email);
    formdata.append('contact', formik.values.contact);
    formdata.append('month', dates);
    formdata.append('notes', formik.values.notes);
    formdata.append('invoice_details', JSON.stringify(rows));
    formdata.append('subtotal', subtotal);
    formdata.append('taxpercent', taxPercentValue);
    formdata.append('taxamount', taxamount);
    formdata.append('total', total);
    formdata.append('payment_date', formik.values.payment_date);
    formdata.append('payment_type', formik.values.payment_type);
    formdata.append('amount', formik.values.amount);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.invoice, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          showSuccessAlert(result.message);
          generatePDF(result.data.id);
          // refresh();
        } else {
          showFailedAlert(result.message);
          // console.log(result?.message, 'Error Register');
        }
      })
      .catch((error) => {
        setloading(false);
        // console.log('error', error);
      })
      .finally(() => setloading(false));
  };

  useEffect(() => {
    getProductList();
  }, []);

  function getProductList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_products, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setProductsData(result.data);
        } else {
          setProductsData([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  const handleInputChange = (field, value) => {
    formik.setFieldValue(field, value);
  };

  const generatePDF = (id) => {
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    var formdata = new FormData();
    formdata.append('invoice_id', id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.invoice_pdf, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        setloading(false);
        const pdfUrl = window.URL.createObjectURL(blob);
        setPdfViewer(pdfUrl);
        setOpen(true);
      })
      .catch((error) => {
        setloading(false);
        // console.log('error', error);
      });
  };

  const handlePrint = () => {
    if (pdfViewer) {
      const pdfWindow = window.open(pdfViewer);
      pdfWindow.onload = () => {
        pdfWindow.print();
      };
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    navigate('/dashboard/invoices');
  };

  useEffect(() => {
    getPaymentType();

    console.log(location);
    if (
      location.state.row.payment_date == null ||
      location.state.row.payment_type == null ||
      location.state.row.amount == null
    ) {
      setDisplayPaymentFields(false);
    } else setDisplayPaymentFields(true);

    setRows(location.state.row.invoice_details);
  }, []);

  const getPaymentType = async () => {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_payment_type, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setGetPayment(result.data);
        } else {
          setGetPayment([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  };

  const handlePaymentTypeChange = (event) => {
    console.log('event:', event.target.value);
    formik.setFieldValue('payment_type', event.target.value);
  };

  const handlePaymentButtonClick = () => {
    debugger;
    setDisplayPaymentFields(!displayPaymentFields);

    if (displayPaymentFields == true) {
      formik.setFieldValue('payment_type', '');
      formik.setFieldValue('payment_date', '');
      formik.setFieldValue('amount', '');
    }
  };
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h3" gutterBottom>
          Edit Invoice
        </Typography>
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 0 }}>
          <Stack spacing={3} backgroundColor="#fff" padding={"16px"} borderRadius="12px">
            {/* INFO SECTION */}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="string"
                  label={'Customer Name'}
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label={'Customer Contact'}
                  // value={name}
                  {...getFieldProps('contact')}
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label={'Customer Email'}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>

            {/* Payment Screen */}
            {displayPaymentFields ? (
              <Grid container width={"100%"} style={{marginTop:"0px",marginLeft:"0px"}} spacing={2}>
                <Grid item xs={12} sm={4} style={{paddingLeft:"0px"}}>
                  <TextField 
                    type="number"
                    label="Payment Amount"
                    fullWidth
                    {...getFieldProps('amount')}
                    // value={location.state.row.amount}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Name"
                      {...getFieldProps('payment_type')}
                      onChange={(e) => handlePaymentTypeChange(e)}
                      // value={location.state.row.payment_type}
                    >
                      {getPayment.map((item) => {
                        return (
                          <MenuItem value={item.name} key={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="date"
                    fullWidth
                    {...getFieldProps('payment_date')}
                    //  value={location.state.row.payment_date}
                  />
                </Grid>
              </Grid>
            ) : null}

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
                marginTop: '16px'
              }}
            >
              <LoadingButton
                sx={{ marginRight: 2 }}
                size="large"
                onClick={handlePaymentButtonClick}
                variant="contained"
                loading={loading}
              >
                {displayPaymentFields ? 'Remove Payment Method' : 'Add Payment Method'}
              </LoadingButton>
            </Box>

            {/* PRODUCT SECTION */}
            <Grid container  >
              <Grid xs={12} sm={12} style={{border:"1px solid #EAECF0", borderRadius:"12px"}}>
                <TableContainer style={{borderRadius:"12px"}} component={Paper}>
                  <Table>
                    <TableHead sx={{backgroundColor:"#78FFB6"}}>
                      <TableRow>
                        <TableCell>PRODUCT</TableCell>
                        <TableCell>QTY</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <FormControl variant="outlined" color="secondary" fullWidth style={{ minWidth: 200 }}>
                            <Autocomplete
                              id="grouped-demo"
                              options={productsData.sort((a, b) => -b.name.localeCompare(a.name))}
                              getOptionLabel={(option) =>
                                `${option.name} ${option.year} ${option.make} ${option.model} ${option.color}`
                              }
                              value={selectedProduct}
                              onChange={(event, newValue) => setSelectedProduct(newValue)}
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Select Product" />
                              )}
                            />
                          </FormControl>
                        </TableCell>

                        <TableCell>
                          <TextField
                            type="number"
                            value={quantity}
                            onChange={(event) => setQuantity(event.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={amount}
                            onChange={(event) => setUnitPrice(event.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: 'bold' }}>{quantity * amount}</span>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            disabled={selectedProduct === '' || quantity === '' || amount === ''}
                            onClick={AddProduct}
                          >
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                      {rows.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {' '}
                              {`${row.name} ${row.year} ${row.make} ${row.model} ${row.color}`}
                            </TableCell>
                            <TableCell>{row.quantity}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{row.total}</TableCell>
                            <TableCell>
                              <Button style={{color:"red"}}  onClick={() => deleteProduct(index)}>Delete</Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {/* NOTES SECTION */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label={'Notes (Optional)'}
                type="string"
                multiline={true}
                rows={8}
                {...getFieldProps('notes')}
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
              />
            </Stack>

            {/* TOTAL SECTION */}
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center'
              }}
            >
              <Grid item xs={12} sm={6} style={{ display: 'flex', marginRight:"16px" }}>
                <Typography align="left" style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>
                  Sub Total:
                </Typography>
                <TextField
                  label="Sub Total"
                  {...getFieldProps('subtotal')}
                  value={calculateSubtotal()}
                  //  value={location.state.row.subtotal}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', marginRight:"16px" }}>
                <Typography align="left" style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>
                  Enter Tax:
                </Typography>
                <TextField
                  label="Enter Tax (%)"
                  type="number"
                  value={formik.values.taxpercent}
                  //  value={location.state.row.taxpercent}
                  onChange={(e) => handleInputChange('taxpercent', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', marginRight:"16px" }}>
                <Typography align="left" style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>
                  Tax Amount:
                </Typography>
                <TextField
                  label="Tax Amount"
                  {...getFieldProps('taxamount')}
                  //  value={location.state.row.taxamount}
                  value={calculateTaxAmount(calculateSubtotal(), formik.values.taxpercent)}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', marginRight:"16px" }}>
                <Typography align="left" style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>
                  Grand Total:
                </Typography>
                <TextField
                  label="Grand Total"
                  {...getFieldProps('total')}
                  value={calculateGrandTotal(
                    calculateSubtotal(),
                    calculateTaxAmount(calculateSubtotal(), formik.values.taxpercent)
                  )}
                  // value={location.state.row.total}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* PRINT MODAL SECTION */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      padding: '8px',
                      maxWidth: '90%',
                      // maxHeight: '80%',
                      overflow: 'auto',
                      width: '100%'
                    }}
                  >
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <>
                        <embed src={pdfViewer} width="100%" height="500px" />
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            width: '100%'
                          }}
                        >
                          <LoadingButton
                            sx={{ marginRight: 2 }}
                            size="large"
                            onClick={handlePrint}
                            variant="contained"
                            loading={loading}
                          >
                            Print
                          </LoadingButton>
                          <Button onClick={handleCloseModal} variant="outlined" color="secondary">
                            Close
                          </Button>
                        </Box>
                      </>
                    )}
                  </div>
                </Modal>
              </Grid>
            </Grid>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%'
              }}
            >
              <LoadingButton
                sx={{ marginRight: 2 }}
                size="large"
                onClick={() => AddInvoice()}
                variant="contained"
                loading={loading}
              >
                {t('AddAgent.buttons.1')}
              </LoadingButton>
              <Button onClick={handleClose} variant="outlined" color="secondary">
                {t('AddAgent.buttons.2')}
              </Button>
            </Box>
          </Stack>
        </Form>
      </FormikProvider>
    </Container>
  );
}
