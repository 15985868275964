import { useState } from 'react';
// material
import { styled, alpha } from '@mui/material/styles';
import { Input, Button, IconButton, InputAdornment, Box, OutlinedInput } from '@mui/material';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchStyle = styled(Input)(({ theme }) => ({
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: '40%', boxShadow: theme.customShadows.z8 }
}));

// ----------------------------------------------------------------------

export default function Searchbar({ setFunction, value, width, placeholder, handleKeyPress }) {
  const [search, setsearch] = useState('');
  return (
    <OutlinedInput
      className="width_full"
      style={{ width: width ? width : '40%', zIndex: 999 }}
      variant="contained"
      contained
      value={value}
      disableUnderline
      placeholder={placeholder ? placeholder : 'Search…'}
      startAdornment={
        <InputAdornment position="start">
          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
        </InputAdornment>
      }
      endAdornment={
        <>
          {/* {value.length > 0 && (
           
          )} */}
        </>
      }
      onChange={(val) => setFunction(val.target.value)}
      onKeyPress={handleKeyPress}
    />
  );
}
