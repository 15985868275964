import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import LogoImage from '../asset/logo.png'

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const company = JSON.parse(localStorage.getItem('company'));
  return (
    <RouterLink to="#">
      <Box
        component="img"
        src={LogoImage}
        sx={{ ...sx }}
      />
    </RouterLink>
  );
}
