import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Avatar,
  MenuItem
} from '@mui/material';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImgsViewer from 'react-images-viewer';

export default function AdminDetailModal({ handleClose, details }) {
  const [loading, setloading] = useState(false);
  const [imagesMultiple, setimagesMultiple] = useState([]);
  const [fullImage, setfullImage] = useState(false);
  const [mainLength, setmainLength] = useState(0);
  function imagesArray() {
    setimagesMultiple([
      {
        src: details.avatar
      }
    ]);
  }
  useEffect(() => {
    imagesArray();
  }, []);

  const [index, setindex] = useState(0);
  function gotoPrevious() {
    if (index >= 0 && index <= mainLength) {
      setindex(index - 1);
    }
  }
  function gotoNext() {
    if (index >= 0 && index < mainLength) {
      setindex(index + 1);
    }
  }

  function settingFullImage(index) {
    imagesArray();
    setindex(index);
    setfullImage(true);
  }

  return (
    <Box>
      <Box p={1} align="center">
        <Typography py={1} align="center" variant="h5">
          User Details
        </Typography>
        <ImgsViewer
          showImgCount={false}
          imgs={imagesMultiple}
          currImg={index}
          onClickPrev={gotoPrevious}
          onClickNext={gotoNext}
          isOpen={fullImage}
          onClose={() => setfullImage(false)}
        />
        {imagesMultiple.length > 0 && (
          <ImageList
            sx={{
              width: '100%',
              marginTop: 2,
              justifyContent: 'center',
              alignContent: 'center',

              display: 'flex'
            }}
            cols={5}
            rowHeight={120}
          >
            {imagesMultiple.map((item, index) => (
              <ImageListItem onClick={() => settingFullImage(index)} key={item.index}>
                <img
                  style={{ height: 120, width: 120, borderRadius: 60 }}
                  src={item.src}
                  alt={'Business Image'}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
        <Typography
          pt={1}
          align="center"
          row
          sx={{ fontSize: 16, fontWeight: 'bold' }}
          component="div"
        >
          {details.firstname} {details.lastname}
        </Typography>
        <Typography pt={0} align="center" row sx={{ fontSize: 12 }} component="div">
          Joined {details.joined_since}
        </Typography>
       
        <Box
          width="100%"
          py={1}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <Typography align="center" row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
            {details.email}
          </Typography>
        </Box>
        <Divider />
      </Box>

      <Box pt={1.5} display="flex" justifyContent="flex-end" flexDirection="row">
        <Button
          style={{ marginRight: 10, width: '100%' }}
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
}
