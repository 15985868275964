import * as React from 'react';
// import Box from '@mui/materia'
import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Box,
  useMediaQuery,
  useTheme,
  MenuItem,
  Divider,
  IconButton,
  Paper
} from '@mui/material';

// components

import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Encryption } from 'src/functions/function';
import format from 'date-fns/format';
import Swal from 'sweetalert2';
import { showSuccessAlert } from 'src/utils/toastifyAlert';
import { useRef } from 'react';
import ActionSheet from 'actionsheet-react';
import MenuPopover from 'src/components/MenuPopover';
import { Link as RouterLink } from 'react-router-dom';

export default function InvoiceRecycled() {
  const [TABLE_HEAD, setTABLE_HEAD] = useState([
    { id: 'id', label: 'SerialNo.', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'make', label: 'Make', alignRight: false },
    { id: 'model', label: 'Model', alignRight: false },
    { id: 'year', label: 'Year', alignRight: false },
    { id: 'color', label: 'Color', alignRight: false },
    { id: 'location', label: 'Location', alignRight: false },
    { id: 'notes', label: 'Notes', alignRight: false }
    // { id: 'updated_at', label: 'Last Updated', alignRight: false }
  ]);

  const [permission, setpermission] = useState([]);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);
  const [showGridActons, setShowGridActons] = useState(false);
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [selectedRow, setselectedRow] = useState(null);
  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);

  const requirement = ['View', 'Restore', 'Delete'];

  const theme = useTheme();
  const [openPop, setOpenPop] = useState(false);
  const [openPopIndex, setOpenPopIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const sheetRef = useRef();

  function FormatDate(date) {
    const myArray = date.split(' ');

    const formatedDate = format(Date.parse(myArray[0]), 'yyyy-MM-dd HH:mm:ss');
    return formatedDate;
  }

  function checkRender() {
    let check = 'Recycled Items';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );

    const requirementGrid = ['Restore', 'Delete'];

    const checkGridActions = localPerm[0]?.permission_types?.filter((items) =>
      requirementGrid.includes(items.permission_type_name) ? true : false
    );

    if (checkGridActions && checkGridActions.length > 0) {
      setShowGridActons(true);
      setTABLE_HEAD((prevState) => [
        ...prevState,
        { id: 'actions', label: 'Actions', alignCenter: true }
      ]);
    } else {
      setShowGridActons(false);
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getProducts(page) {
    setloading(true);
    setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    formdata.append('status', 'deleted');
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_products, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          list.length = 0;
          result.data.sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => {
        // console.log(error, 'errorrrrrr')
      })
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  function DeleteProducts(id, index) {
    setselectedIndex(index);
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_APP));
    formdata.append('status', 'permanent deleted');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    Swal.fire({
      title: 'Confirm Deletion',
      text: 'Are you sure you want to permanently delete this product?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete',
      confirmButtonColor: pallete.primary.main,
      cancelButtonText: 'No, cancel',
      backdrop: false,
      customClass: {
        confirmButton: 'custom-confirm-button'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${api.deleteProducts}`, requestOptions)
          .then((response) => response.text())
          .then((result_) => {
            const result = JSON.parse(result_);
            if (result.success === true) {
              showSuccessAlert(result.message);
              getProducts(page);
            }
          })
          .catch((error) => {
            console.error('Error deleting product:', error);
          })
          .finally(() => {
            setdeleting(false);
            setselectedIndex(null);
          });
      } else {
        setdeleting(false);
        setselectedIndex(null);
      }
    });
  }

  useEffect(() => {
    checkRender();
    getProducts();
  }, []);

  const isUserNotFound = list.length === 0;

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getProducts(value);
  };

  const handleKeyPress = (event) => {
    // console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getProducts();
    }
  };

  function ReStored(id) {
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_APP));
    formdata.append('status', 'active');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    Swal.fire({
      title: 'Confirm Restoration',
      text: 'Are you sure you want to restore this product?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, restore',
      cancelButtonText: 'No, cancel',
      confirmButtonColor: pallete.primary.main,
      customClass: {
        confirmButton: 'custom-confirm-button'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${api.deleteProducts}`, requestOptions)
          .then((response) => response.text())
          .then((result_) => {
            const result = JSON.parse(result_);
            if (result.success === true) {
              showSuccessAlert(result.message);
              getProducts(page);
            }
          })
          .catch((error) => {
            console.error('Error restoring product:', error);
          })
          .finally(() => {
            setdeleting(false);
            setselectedIndex(null);
          });
      } else {
        setdeleting(false);
        setselectedIndex(null);
      }
    });
  }

  return (
    <Page title={`Recycled Items | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h3">Recycled Items</Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder="Search Parts by (make, model, year, color)"
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{
                width: '5%',
                marginLeft: '2.5%',
                background: '#EDEDED',
                color: '#000',
                boxShadow: `0px 26px 40px 0px rgba(188, 202, 255, 0.13)`
              }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getProducts();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Box style={{ margin: '0px 20px 16px 20px' }}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800, overflow: 'visible' }}>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                  <TableBody sx={{ overflow: 'visible' }}>
                    {list.map((row, index) => {
                      const {
                        sku,
                        id,
                        name,
                        make,
                        model,
                        start_year,
                        end_year,
                        year,
                        color,
                        location,
                        notes,
                        updated_at
                      } = row;

                      return (
                        <TableRow
                          hover
                          style={{
                            cursor: 'pointer'
                          }}
                          key={sku}
                          role="checkbox"
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              width: 150
                            }}
                          >
                            {sku ? sku : id}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              width: 150
                            }}
                          >
                            {name && name ? name : ''}
                          </TableCell>

                          <TableCell component="th" scope="row" padding="normal">
                            <Typography
                              style={{
                                fontSize: 14,
                                textAlign: 'justify'
                              }}
                            >
                              {make && make ? make : ''}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                            style={{
                              width: 100
                            }}
                          >
                            {model && model ? model : ''}
                          </TableCell>
                          <TableCell>
                            {start_year || ''} - {end_year || ''}
                          </TableCell>
                          <TableCell>{color || ''}</TableCell>
                          <TableCell>{location || ''}</TableCell>
                          <TableCell>{notes || ''}</TableCell>
                          {/* <TableCell>{FormatDate(updated_at)}</TableCell> */}

                          <TableCell align="center" style={{ flexDirection: 'column' }}>
                            {' '}
                            <IconButton
                              onClick={(e) => {
                                setOpenPop(!openPop);
                                setOpenPopIndex(id);
                                setAnchorEl(e.currentTarget);
                              }}
                            >
                              <Iconify icon={'ri:more-fill'} />
                            </IconButton>
                            <MenuPopover
                              open={openPop && openPopIndex === id}
                              onClose={() => {
                                setOpenPop(false);
                                setAnchorEl(null);
                              }}
                              anchorEl={anchorEl}
                              sx={{ width: 220 }}
                            >
                              <Stack flexDirection={'column'}>
                                {permission.includes('Restore') && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setOpenPop(false);
                                      ReStored(row.id);
                                    }}
                                    style={{
                                      width: '100%',
                                      paddingTop: '8px',
                                      paddingBottom: '8px'
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    component={RouterLink}
                                    to="#"
                                  >
                                    <Iconify
                                      color={pallete.grey[600]}
                                      icon="ic:twotone-settings-backup-restore"
                                      sx={{ mr: 1 }}
                                      style={{ fontSize: '20px' }}
                                    />{' '}
                                    Restore
                                  </MenuItem>
                                )}
                                {permission.includes('Delete') && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setOpenPop(false);
                                      DeleteProducts(id, index);
                                    }}
                                    style={{
                                      width: '100%',
                                      paddingTop: '8px',
                                      paddingBottom: '8px'
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                    component={RouterLink}
                                    to="#"
                                  >
                                    <Iconify
                                      color={'red'}
                                      icon="ic:baseline-delete"
                                      sx={{ mr: 1 }}
                                      style={{ fontSize: '20px' }}
                                    />{' '}
                                    Delete
                                  </MenuItem>
                                )}
                              </Stack>
                            </MenuPopover>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !loading && (
                    <TableBody sx={{ overflow: 'visible' }}>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={search} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              variant="outlined"
              color="secondary"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
