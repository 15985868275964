import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Businesses from './pages/Inventoryy';
import Admin from './pages/Employee';
import Rolepermission from './pages/Rolepermission';
import NotFound from './pages/Page404';
import Requests from './pages/Settings';
import Documents from './pages/Inventory';
import Location from './pages/Location';
import DocumentModal from './sections/@dashboard/documents/documentModal';
import PayslipModal from './sections/@dashboard/documents/payslipModal';
import AddInvoice from './pages/AddInvoice';
import Invoice from './pages/Invoice';
import InvoiceRecycled from './pages/InvoiceRecycled';
import EditInvoice from './pages/EditInvoice';
import Parts from './pages/Parts';
import Register from './pages/Register';
import Payment from './pages/Payment';

// ----------------------------------------------------------------------

export default function Router() {
  let localPerm = JSON.parse(localStorage.getItem('permissions'));

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'pricing', element: <Payment /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'parts',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'parts')?.length >
            0 ? (
              <Parts />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'roles',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'role/permission')
              ?.length > 0 ? (
              <Rolepermission />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'employees',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'employees')?.length >
            0 ? (
              <Admin />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'settings',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'settings')?.length >
            0 ? (
              <Requests />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'inventory',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'inventory')?.length >
            0 ? (
              <Businesses />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'location',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'locations')?.length >
            0 ? (
              <Location />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'invoices',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'invoices')?.length >
            0 ? (
              <Invoice />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'addinvoice',
          element:
            localPerm?.filter((item) => {
              return item.permission_name.toLowerCase() === 'invoices';
            })?.length > 0 ? (
              <AddInvoice />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'editinvoice/:id',
          element:
            localPerm?.filter((item) => {
              return item.permission_name.toLowerCase() === 'invoices';
            })?.length > 0 ? (
              <EditInvoice />
            ) : (
              <Navigate to="/404" />
            )
        },
        {
          path: 'recycled',
          element:
            localPerm?.filter((item) => {
              return item.permission_name.toLowerCase() === 'recycled items';
            })?.length > 0 ? (
              <InvoiceRecycled />
            ) : (
              <Navigate to="/404" />
            )
        }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
