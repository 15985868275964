import * as React from 'react';
// import Box from '@mui/materia'
import { useState, useEffect } from 'react';
import { Link, Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Modal,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Box,
  TableSortLabel,
  TableHead,
  Grid,
  Paper,
  IconButton,
  MenuItem
} from '@mui/material';
import Swal from 'sweetalert2';
// components
import MyPage from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';
import { showSuccessAlert } from 'src/utils/toastifyAlert';
import MenuPopover from 'src/components/MenuPopover';

export default function Invoice() {
  const [TABLE_HEAD, setTABLE_HEAD] = useState([
    { id: 'invoice_no', label: 'OrderNo', alignRight: false },
    { id: 'month', label: 'OrderDate', alignRight: false },
    { id: 'name', label: 'CustomerName', alignRight: false },
    { id: 'subtotal', label: 'SubTotal', alignRight: false },
    { id: 'taxamount', label: 'TaxAmount', alignRight: false },
    { id: 'total', label: 'GrandTotal', alignRight: false },
    { id: 'product', label: 'Product', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'contact', label: 'Contact', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false }
  ]);

  const [permission, setpermission] = useState([]);

  const requirement = ['View', 'Add', 'Edit', 'Delete', 'Change status'];

  const [showGridActons, setShowGridActons] = useState(false);

  function checkRender() {
    let check = 'Invoices';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );

    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );
    const requirementGrid = ['Edit', 'Delete', 'Change status'];
    const checkGridActions = localPerm[0]?.permission_types?.filter((items) =>
      requirementGrid.includes(items.permission_type_name) ? true : false
    );
    if (checkGridActions && checkGridActions.length > 0) {
      setShowGridActons(true);
      setTABLE_HEAD((prevState) => [
        ...prevState,
        { id: 'actions', label: 'Actions', alignRight: true }
      ]);
    } else {
      setShowGridActons(false);
    }
  }

  const [openPop, setOpenPop] = useState(false);
  const [openPopIndex, setOpenPopIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByDirection, setOrderByDirection] = useState('asc');
  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [part, setpart] = useState(null);
  const [open, setOpen] = useState(false);
  const [pdfViewer, setPdfViewer] = useState(null);
  const handleProductModalClose = () => setInvoiceModal(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const refreshpaymentType = () => {
    // setInvoiceModal(false);
    getInvoices();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getInvoices(page) {
    setloading(true);
    setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_invoice, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloadingTable(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          list.length = 0;
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.error(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  function DeleteInvoice(id, index) {
    setselectedIndex(index);
    setdeleting(true);

    // Show confirmation modal
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: pallete.primary.main,
      reverseButtons: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const token = JSON.parse(localStorage.getItem('token'));
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);
        var formdata = new FormData();
        formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_APP));

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
        fetch(`${api.delete_invoice}`, requestOptions)
          .then((response) => response.text())
          .then((result_) => {
            const result = JSON.parse(result_);
            if (result.success === true) {
              // Delete successful
              showSuccessAlert(result.message);
              getInvoices(page);
            } else {
              // Delete failed
              Swal.fire('Error!', 'Failed to delete data.', 'error');
            }
          })
          .catch((error) => {
            // Error occurred during delete
            Swal.fire('Error!', 'An error occurred while deleting data.', 'error');
          })
          .finally(() => {
            setdeleting(false);
            setselectedIndex(null);
          });
      } else {
        // User clicked Cancel, do nothing
        setdeleting(false);
        setselectedIndex(null);
      }
    });
  }

  useEffect(() => {
    checkRender();
    getInvoices();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderByDirection === 'asc';
    setOrderByDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isUserNotFound = list.length === 0;

  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getInvoices(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setloadingTable(true);
      getInvoices();
    }
  };

  const handleStatusUpdate = (id, status) => {
    setSelectedInvoice(status);
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('id', id);
    formdata.append('status', status);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.update_invoice_status, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          // refresh();
          showSuccessAlert(result.message);
        } else {
          //   setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => {
        setloading(false);
      })
      .finally(() => setloading(false));
  };

  const handleIconClick = (id, status) => {
    setSelectedInvoice({ id, status });
    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to update the status of invoice ${id} to ${
        status === 'pending' ? 'paid' : 'pending'
      }`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      confirmButtonColor: pallete.primary.main,
      cancelButtonText: 'No, cancel!',
      customClass: {
        confirmButton: 'custom-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleStatusUpdate(id, status === 'pending' ? 'paid' : 'pending');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setSelectedInvoice(null);
      }
    });
  };

  const generatePDF = (id) => {
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    var formdata = new FormData();
    formdata.append('invoice_id', id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.invoice_pdf, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        setloading(false);
        const pdfUrl = window.URL.createObjectURL(blob);
        setPdfViewer(pdfUrl);
        setOpen(true);
      })
      .catch((error) => {
        setloading(false);
        // console.log('error', error);
      });
  };

  const handlePrint = () => {
    if (pdfViewer) {
      const pdfWindow = window.open(pdfViewer);
      pdfWindow.onload = () => {
        pdfWindow.print();
      };
    }
  };

  return (
    <MyPage title={`Invoices | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction={{ md: 'row' }} alignItems="center" justifyContent="space-between" gap={1} mb={2}>
          <Typography variant="h3" >
            Invoice List
          </Typography>
          {permission.includes('Add') && (
            <>
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/addinvoice"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Invoice
              </Button>
            </>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder="Search Invoice"
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{ width: '5%', marginLeft: '2.5%',background:"#EDEDED",color:"#000",boxShadow:`0px 26px 40px 0px rgba(188, 202, 255, 0.13)` }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getInvoices();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Box>
            <Scrollbar style={{margin:"0px 20px 16px 20px"}}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead sx={{backgroundColor:"#78FFB6"}}>
                    <TableRow>
                      {TABLE_HEAD.map((headCell,index) => {
                        return (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? 'right' : 'left'}
                            // style={{ width: `${headCell.label.length * 10}px` }}
                          >
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? orderByDirection : 'asc'}
                              onClick={() => handleRequestSort(headCell.id)}
                              className="tableFontHeading"
                            >
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  {/* <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} /> */}
                  <TableBody sx={{ minWidth: 800, overflow: 'visible' }}>
                    {list
                      .slice()
                      .sort((a, b) => {
                        if (orderByDirection === 'asc') {
                          return a[orderBy] > b[orderBy] ? 1 : -1;
                        } else {
                          return a[orderBy] < b[orderBy] ? 1 : -1;
                        }
                      })
                      .map((row, index) => {
                        const {
                          id,
                          invoice_details,
                          month,
                          contact,
                          email,
                          name,
                          subtotal,
                          taxamount,
                          status,
                          total,
                          invoice
                        } = row;

                        return (
                          <TableRow
                            hover
                            style={{
                              cursor: 'pointer'
                            }}
                            key={id}
                            role="checkbox"
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={
                                {
                                  // width: 150
                                }
                              }
                            >
                              {id && id ? id : ''}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 150
                              }}
                            >
                              {month && month ? month : ''}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 150
                              }}
                            >
                              {name && name ? name : ''}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={
                                {
                                  // width: 50
                                }
                              }
                            >
                              {subtotal && subtotal ? subtotal : 0}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={
                                {
                                  // width: 50
                                }
                              }
                            >
                              {taxamount && taxamount ? taxamount : 0}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={
                                {
                                  // width: 50
                                }
                              }
                            >
                              {total && total ? total : 0}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 150
                              }}
                            >
                              {invoice_details[0] && invoice_details[0].name
                                ? invoice_details[0].name
                                : ''}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 150
                              }}
                            >
                              {email && email ? email : ''}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 150
                              }}
                            >
                              {contact && contact ? contact : ''}
                            </TableCell>

                            {/* <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 150
                              }}
                            >
                              {invoice ? (
                                <Button
                                  variant="contained"
                                  component="a"
                                  href={invoice}
                                  target="_blank"
                                >
                                  Invoice
                                </Button>
                              ) : (
                                <span>N/A</span>
                              )}
                            </TableCell> */}

                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              style={{
                                width: 150
                              }}
                            >
                              {status && status ? status : ''}
                            </TableCell>

                            <TableCell align="right" style={{ flexDirection: 'column' }}>
                            <IconButton
                                onClick={(e) => {
                                  setOpenPop(!openPop);
                                  setOpenPopIndex(id);
                                  setAnchorEl(e.currentTarget);
                                }}
                              >
                                <Iconify icon={'ri:more-fill'} />
                              </IconButton>

                              <MenuPopover
                                open={openPop && openPopIndex === id}
                                onClose={() => {
                                  setOpenPop(false);
                                  setAnchorEl(null);
                                }}
                                anchorEl={anchorEl}
                                sx={{ width: 220 }}
                              >
                                <Stack flexDirection={'column'}>
                                  {permission.includes('Edit') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setOpenPop(false);
                                        navigate(`/dashboard/editinvoice/${row.id}`, {
                                          state: { row: row }
                                          })
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined"
                                      color="secondary"
                                      // component={RouterLink}
                                      // to="#"
                                    >
                                      <Iconify
                                        onClick={() =>{}}
                                        style={{ fontSize: '20px' }}
                                        sx={{ mr: 1 }}
                                        color={pallete.grey[600]}
                                        icon="tabler:edit"
                                  />
                                      Edit
                                    </MenuItem>
                                  )}
                                  {permission.includes('Delete') && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setOpenPop(false);
                                        DeleteInvoice(id, index);
                                      }}
                                      style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                      variant="outlined"
                                      color="secondary"
                                    >
                                       <Iconify
                                        onClick={(event) => {
                                        // DeleteInvoice(id, index);
                                        }}
                                        style={{ fontSize: '20px' }}
                                        sx={{ mr: 1 }}
                                        color={"red"}
                                        icon="ic:baseline-delete"
                                  />
                                      Delete
                                    </MenuItem>
                                  )}

                                      {permission.includes('View') && (
                                        <MenuItem
                                          onClick={(event) => {
                                          event.preventDefault();
                                          event.stopPropagation();
                                          setOpenPop(false);
                                          setpart(row);
                                          generatePDF(row.id);
                                          }}
                                          style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                          variant="outlined"
                                          color="secondary"
                                        >
                                          <Iconify
                                          color={pallete.grey[600]}
                                          icon="eva:eye-fill"
                                          style={{ fontSize: '20px', }}
                                          sx={{ mr: 1 }}
                                          />
                                          View
                                        </MenuItem>
                                        )}

                                      {permission.includes('Change status') && (
                                  <MenuItem
                                    onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setOpenPop(false);
                                    setpart(row);
                                    handleIconClick(row.id, row.status)
                                    }}
                                    style={{ width: '100%',paddingTop:"8px",paddingBottom:"8px" }}
                                    variant="outlined"
                                    color="secondary"
                                  >
                                    <Iconify
                                    onClick={() => {}}
                                    style={{ fontSize: '20px', }}
                                    sx={{ mr: 1 }}
                                    color={pallete.grey[600]}
                                    icon="mdi:apps-box"
                                  />
                                    Change Status
                                  </MenuItem>
                                      )}

                                      {permission.includes('Edit') && (
                                        <>
                                        {invoice && (
                                          <MenuItem
                                            onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            window.open(invoice, '_blank', 'noopener,noreferrer');
                                            }}
                                            href={invoice}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ width: '100%' , paddingTop:"8px",paddingBottom:"8px" }}
                                            variant="outlined"
                                            color="secondary"
                                          >
                                            <Iconify
                                              style={{ fontSize: '20px' }}
                                              sx={{ mr: 1 }}
                                              color={pallete.grey[600]}
                                              icon="tabler:download"
                                              to={invoice}
                                              />
                                                Download
                                          </MenuItem>
                                         )}
                                          </>
                                      )}
                                </Stack>
                              </MenuPopover>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div
                          style={{
                            outline: 'none',
                            backgroundColor: 'white',
                            padding: '8px',
                            maxWidth: '90%',
                            // maxHeight: '80%',
                            overflow: 'auto',
                            width: '100%'
                          }}
                        >
                          {loading ? (
                            <p>Loading...</p>
                          ) : (
                            <>
                              <embed src={pdfViewer} width="100%" height="500px" />
                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  width: '100%'
                                }}
                              >
                                <LoadingButton
                                  sx={{ marginRight: 2 }}
                                  size="large"
                                  onClick={handlePrint}
                                  variant="contained"
                                  loading={loading}
                                >
                                  Print
                                </LoadingButton>
                                <Button onClick={() => setOpen(false)} variant="outlined" color="secondary">
                                  Close
                                </Button>
                              </Box>
                            </>
                          )}
                        </div>
                      </Modal>
                    </Grid>
                  </Grid>

                  {isUserNotFound && !loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={search} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              variant="outlined" 
              color="secondary"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </MyPage>
  );
}
