import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Alert,
  MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AdminModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [selectedFile, setSelectedFile] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required(t('AddAgent.required.0')),
    lastName: Yup.string().required(t('AddAgent.required.1')),
    email: Yup.string().email(t('AddAgent.required.5')).required(t('AddAgent.required.2')),
    username: Yup.string().required(t('username required')),
    password: Yup.string().required(t('AddAgent.required.3')),
    confirmpassword: Yup.string().required(t('AddAgent.required.4')),
    role: Yup.number().required(t('AddAgent.required.7 required')),
    position: Yup.string().required(t('AddAgent.required.8 required')),
    dateHired: Yup.date().required(t('AddAgent.required.9 required')),
    dateResigned: Yup.date(),
    attachment: Yup.date()
  });

  const formik = useFormik({
    initialValues: {
      firstName: data?.firstname,
      lastName: data?.lastname,
      email: data?.email,
      username: data?.username,
      password: '',
      confirmpassword: '',
      role: data?.role.id || '',
      position: data?.position,
      dateResigned: data?.dateResigned || '',
      attachment: data?.attachment,
      dateHired: data?.date_hired
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddAdmin()
  });

  const [permission, setpermission] = useState([]);
  const requirement = ['Change Role'];

  function checkRender() {
    let check = 'Employees';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    console.log(localPerm);
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );
  }

  const [url, seturl] = useState(null);

  useEffect(() => {
    checkRender();
    GetUsersRoles();
    if (data) {
      data.document.length > 0 && seturl(data.document[0].attachment);
    }
  }, []);

  
  const AddAdmin = () => {
    setloading(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const company = JSON.parse(localStorage.getItem('company'));
    

    const body = {
      role: Encryption(formik.values.role, process.env.REACT_APP_SECRET_KEY),
      username: Encryption(formik.values.username, process.env.REACT_APP_SECRET_KEY),
      firstname: Encryption(formik.values.firstName, process.env.REACT_APP_SECRET_KEY),
      lastname: Encryption(formik.values.lastName, process.env.REACT_APP_SECRET_KEY),
      email: Encryption(formik.values.email, process.env.REACT_APP_SECRET_KEY),
      password: Encryption(formik.values.password, process.env.REACT_APP_SECRET_KEY),
      confirm_password: Encryption(formik.values.confirmpassword, process.env.REACT_APP_SECRET_KEY),
      position: Encryption(formik.values.position, process.env.REACT_APP_SECRET_KEY),
      date_hired: Encryption(formik.values.dateHired || 'N/A', process.env.REACT_APP_SECRET_KEY),
      date_resigned: Encryption(
        formik.values.dateResigned || 'N/A',
        process.env.REACT_APP_SECRET_KEY
      ),
      attachment: Encryption(selectedFile, process.env.REACT_APP_SECRET_KEY),
      company_id: Encryption(company.id, process.env.REACT_APP_SECRET_KEY),
      company_name: Encryption(company.name, process.env.REACT_APP_SECRET_KEY)
    };
    var formdata = new FormData();
    for (const key in body) {
      formdata.append(key, body[key]);
    }
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);
    myHeaders.append('Accept', 'application/json');
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: myHeaders 
    };

    fetch(data ? api.update_profile : api.register, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          refresh();
        } else {
          setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => {
        setloading(false);
      })
      .finally(() => setloading(false));
  };

  function GetUsersRoles(data) {
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_roles_names, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setRoles(result.data);
          // console.log('API call successful:', result);
        } else {
          console.error('API call failed:', result.error);
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  }
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleDownload = () => {
    // if (selectedFile && selectedFile instanceof File) {
    //   const downloadLink = document.createElement('a');
    //   const fileUrl = URL.createObjectURL(selectedFile);
    //   downloadLink.href = fileUrl;
    //   downloadLink.download = selectedFile.name;
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   document.body.removeChild(downloadLink);
    // }

    window.open(url);
  };
  const passwordGenerator = () => {
    const result = (Math.random() + 1).toString(36).substring(0);
    formik.values.password = result;
    formik.values.confirmpassword = result;
    formik.validateField();
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack
          spacing={isMobile ? 2 : 3}
          sx={{ height: 'calc(100vh - 200px)', overflowY: 'scroll' }}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('AddAgent.fields.0')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label={t('AddAgent.fields.1')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={t('AddAgent.fields.2')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label={t('username')}
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              disabled={permission.includes('Change Role') == false}
              fullWidth
              select
              label={t('AddAgent.fields.7')}
              {...getFieldProps('role')}
              SelectProps={{ native: false }}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
            >
              <MenuItem value="">{t('Select Role')}</MenuItem>
              {roles?.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role?.role_name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              label={t('AddAgent.fields.8')}
              {...getFieldProps('position')}
              error={Boolean(touched.position && errors.position)}
              helperText={touched.position && errors.position}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="date"
              label={t('AddAgent.fields.9')}
              {...getFieldProps('dateHired')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                placeholder: 'YYYY-MM-DD' // Customize the placeholder text
              }}
              error={Boolean(touched.dateHired && errors.dateHired)}
              helperText={touched.dateHired && errors.dateHired}
            />
            <TextField
              fullWidth
              type="date"
              label={t('date Resigned')}
              {...getFieldProps('dateResigned')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                placeholder: 'YYYY-MM-DD' // Customize the placeholder text
              }}
              error={Boolean(touched.dateResigned && errors.dateResigned)}
              helperText={touched.dateResigned && errors.dateResigned}
            />
          </Stack>
          <>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={t('AddAgent.fields.3')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                value={formik.values.confirmpassword}
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                label={t('AddAgent.fields.4')}
                {...getFieldProps('confirmpassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setshowConfirmPassword((prev) => !prev)}
                      >
                        <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                helperText={touched.confirmpassword && errors.confirmpassword}
              />
            </Stack>
            {/* <TextField
              fullWidth
              type="file"
              label="Select File"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            /> */}
            <Button
              sx={{
                marginRight: 1
              }}
              onClick={passwordGenerator}
              variant="outlined"
              color="secondary"
            >
              {t('AddAgent.buttons.0')}
            </Button>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Button
                style={{
                  width: '100%',
                  height: 50
                }}
                variant={selectedFile ? 'outlined' : 'outlined'}
                component="label"
                color="secondary"
              >
                {selectedFile?.name ? selectedFile?.name : 'Add Document'}
                <input
                  type="file"
                  hidden
                  {...getFieldProps('attachment')}
                  accept="file/*"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    console.log(e.target.files[0].name, 'selectedFile');
                  }}
                />
              </Button>
              {url && (
                <Button
                  style={{
                    width: '100%',
                    height: 50
                  }}
                  onClick={handleDownload}
                  variant="outlined" color="secondary"
                >
                  Download File
                </Button>
              )}
            </Stack>
          </>
          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
              style={{ fontSize: 12, marginBottom: 20 }}
            >
              {Object.entries(alert?.message).map(([field, message]) => (
                <p key={field}>
                  <b>{field}:</b> {message}
                </p>
              ))}
            </Alert>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => AddAdmin()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
