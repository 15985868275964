import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './Languages/en.json';
import zh from './Languages/zh.json';

let language = null;

language = localStorage.getItem('language');
if (language !== null && language !== undefined) {
  //i18n.changeLanguage(language);
} else {
  localStorage.setItem('language', 'en');
}

i18n.use(initReactI18next).init({
  lng: language,
  fallbackLng: 'en',
  resources: {
    en: en,
    zh: zh
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
