import { useEffect, useState } from 'react';
import { Stack, Button, Box, Grid, FormControl, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';
import { useTranslation } from 'react-i18next';

export default function ProductBulkEditModal({ handleClose, handleBulkEdit, editedData }) {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [modelList, setModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [newData, setNewData] = useState({
    year: '',
    color: '',
    location: '',
    make: '',
    model: ''
  });

  useEffect(() => {
    getColorList();
    getYearList();
    getLocationList();
    getModelList();
    getMakeList();
  }, []);

  useEffect(() => {
    editedData(newData);
  }, [newData]);

  function getModelList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_model, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setModelList(result.data);
        } else {
          setModelList([]);
        }
      })
      .finally(() => {
        setloading(false);
      });
  }

  function getMakeList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_make, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setMakeList(result?.data);
        } else {
          setMakeList([]);
        }
      })
      .catch((error) => console.error(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  function getYearList() {
    setloading(true);
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_year, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setYearList(result.data);
        } else {
          setYearList([]);
        }
      })
      .finally(() => {
        setloading(false);
      });
  }

  function getColorList() {
    setloading(true);
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_color, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setColorList(result.data);
        } else {
          setColorList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  function getLocationList() {
    setloading(true);
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_location, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          setLocationList(result.data);
        } else {
          setLocationList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  const handleMakeChange = (value) => {
    const makeData = value.map((make) => make.name).join(',');
    setNewData((prevData) => ({
      ...prevData,
      make: makeData
    }));
  };

  const handleModelChange = (value) => {
    const modelData = value.map((model) => model.name).join(',');
    setNewData((prevData) => ({
      ...prevData,
      model: modelData
    }));
  };

  const handleYearChange = (value) => {
    const yearData = value.map((year) => year.name).join(',');
    setNewData((prevData) => ({
      ...prevData,
      year: yearData
    }));
  };

  const handleColorChange = (value) => {
    const colorData = value.map((color) => color.name).join(',');
    setNewData((prevData) => ({
      ...prevData,
      color: colorData
    }));
  };

  const handleLocationChange = (value) => {
    setNewData((prevData) => ({
      ...prevData,
      location: value?.name
    }));
  };

  return (
    <>
      <Stack spacing={3}>
        <div style={{ padding: 20, maxHeight: '70vh', overflowY: 'auto' }}>
          <Grid container spacing={1} alignItems="center" style={{ marginBottom: '20px' }}>
            {/* <Grid item lg={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={makeList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => handleMakeChange(value)}
                  renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
                  renderInput={(params) => <TextField {...params} label="Make" />}
                />
              </FormControl>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={modelList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => handleModelChange(value)}
                  renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
                  renderInput={(params) => <TextField {...params} label="Model" />}
                />
              </FormControl>
            </Grid> */}


            <Grid item lg={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={yearList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => handleYearChange(value)}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Year" />}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={colorList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => handleColorChange(value)}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Color" />}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              <FormControl fullWidth>
                <Autocomplete
                  options={locationList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => handleLocationChange(value)}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Location" />}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <LoadingButton
            sx={{ marginRight: 2 }}
            size="large"
            onClick={handleBulkEdit}
            variant="contained"
            loading={loading}
          >
            {t('AddAgent.buttons.1')}
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            {t('AddAgent.buttons.2')}
          </Button>
        </Box>
      </Stack>
    </>
  );
}
