import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  Button,
  Box,
  Typography,
  Alert,
  Autocomplete,
  Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { api } from 'src/Api';
import { useTranslation } from 'react-i18next';
import { showFailedAlert, showSuccessAlert } from 'src/utils/toastifyAlert';
import moment from 'moment';

// ----------------------------------------------------------------------

export default function ProductModal({ handleClose, refresh, data, list }) {
  const { t } = useTranslation();

  const [checkBox, setcheckBox] = useState(false);
  const [checkerror, setcheckerror] = useState(false);
  const [loading, setloading] = useState(false);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [modelList, setModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [variantList, setvariantList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);

  const label = { inputProps: { 'aria-label': 'Master Admin Rights' } };

  const RegisterSchema = Yup.object().shape({
    // name: Yup.string().required(t('Part name is required')),
    // make: Yup.array().nullable().required(t('Make is Required')),
    // year: Yup.array().nullable().required(t('Year is Required')),
    // model: Yup.array().nullable().required(t('Model is Required')),
    // // variant: Yup.array().nullable().required(t('Variant is Required')),
    // location: Yup.string().nullable().required(t('Location is Required'))
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      start_year: data?.year || '',
      end_year: data?.year || '',
      make: data?.make || [],
      model: data?.model || [],
      location: data?.location || [],
      color: data?.color || [],
      notes: data?.notes || '',
      variant: data?.variant || []
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddProduct()
  });

  useEffect(() => {
    if (checkBox === true) {
      setcheckerror(false);
    }
  }, [checkBox]);

  useEffect(() => {
    getModelList();
  }, []);

  useEffect(() => {
    getMakeList();
  }, []);

  useEffect(() => {
    getVariantList();
  }, []);

  useEffect(() => {
    getColorList();
  }, []);

  useEffect(() => {
    getYearList();
  }, []);

  useEffect(() => {
    getLocationList();
  }, []);

  useEffect(() => {
    getInvoiceList();
  }, []);

  // GET MODEL LIST
  function getModelList(make_name) {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    if (make_name) {
      formdata.append('make_name', make_name);
    }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_model, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setModelList(result?.data);
        } else {
          setModelList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET variant LIST
  function getVariantList(variant_name) {
    setloading(true);
    if (variant_name) {
      const name = variant_name.replace(/\s/g, '').toLowerCase();
      if (name == 'airbag') {
        setvariantList([
          { name: 'Left Knee Airbag', id: 1 },
          { name: 'Right Knee Airbag', id: 2 },
          { name: 'Left Roof Airbag', id: 3 },
          { name: 'Right Roof Airbag', id: 4 }
        ]);
        setloading(false);
        return;
      }
    }
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    if (variant_name) {
      formdata.append('variant_name', variant_name);
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_variant, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setvariantList(result?.data);
        } else {
          setvariantList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET MAKE LIST
  function getMakeList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_make, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setMakeList(result?.data);
        } else {
          setMakeList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET YEAR LIST
  function getYearList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_year, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setYearList(result?.data);
        } else {
          setYearList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET Color LIST
  function getColorList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_color, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setColorList(result?.data);
        } else {
          setColorList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET Location LIST
  function getLocationList() {
    setloading(true);
    // setloadingTable(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_location, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);

        if (result?.success === true) {
          setLocationList(result?.data);
        } else {
          setLocationList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  // GET INVOICE LIST
  function getInvoiceList() {
    setloading(true);
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_part, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        // setloadingTable(false);
        const result = JSON.parse(result_);
        if (result?.success === true) {
          setInvoiceList(result.data);  
        } else {
          setInvoiceList([]);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
      });
  }

  const AddProduct = () => {
    if (Number(formik.values.start_year) > Number(formik.values.end_year ||  moment().format('yyyy'))) {
      console.log(formik.values.start_year,formik.values.end_year)
      setalert({ visible: true, message: {
        start_year: "Start year cannot be greater than the end year."
      }});
      return
    }

    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const company = JSON.parse(localStorage.getItem('company'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();

    data && formdata.append('id', data?.id);
    formdata.append('name', formik.values.name);
    formdata.append('notes', formik.values.notes);
    const joinArrayValues = (fieldName) =>
      Array.isArray(formik.values[fieldName])
        ? formik.values[fieldName].join(',')
        : formik.values[fieldName];
    const endYearValue = formik.values.end_year ? joinArrayValues('end_year') : moment().format('yyyy'); ;
    formdata.append('make', joinArrayValues('make'));
    formdata.append('model', joinArrayValues('model'));
    formdata.append('start_year', joinArrayValues('start_year'));
    formdata.append('end_year',endYearValue);
    formdata.append('color', joinArrayValues('color'));
    formdata.append('location', joinArrayValues('location'));
    formdata.append('variant', joinArrayValues('variant'));
    formdata.append('company_id', company?.id);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.saveProducts, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result?.success === true) {
          showSuccessAlert(result?.message);
          refresh();
        } else {
          setalert({ visible: true, message: result?.message });
          showFailedAlert(result.message);
          // console.log(result?.message, 'Error Register');
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{ padding: 20, maxHeight: '70vh', overflowY: 'auto' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="grouped-demo"
              options={invoiceList.sort((a, b) => -b.name.localeCompare(a.name))}
              // groupBy={(option) => option.name}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(event, newValue) => {
                formik.setFieldValue('name', newValue.name);
                getVariantList(newValue.name);
              }}
              fullWidth
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Name" fullWidth />}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              fullWidth
              disablePortal
              id="combo-box-demo"
              SelectProps={{ native: false }}
              options={[...(makeList || [])]}
              getOptionLabel={(make) => make.name}
              onChange={(e, newValue) => {
                const selectedValues = newValue.map((option) => option.name);
                formik.setFieldValue('make', selectedValues);
                getModelList(selectedValues);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Make"
                  {...getFieldProps('make')}
                  error={Boolean(touched.make && errors.make)}
                  helperText={touched.make && errors.make}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              fullWidth
              disablePortal
              id="combo-box-demo"
              SelectProps={{ native: false }}
              options={[...(modelList || [])]}
              getOptionLabel={(model) => model.name}
              onChange={(e, newValue) => {
                const selectedValues = newValue.map((option) => option.name);
                formik.setFieldValue('model', selectedValues);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Model"
                  {...getFieldProps('model')}
                  error={Boolean(touched.model && errors.model)}
                  helperText={touched.model && errors.model}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              fullWidth
              disablePortal
              id="combo-box-demo"
              SelectProps={{ native: false }}
              options={[...(colorList || [])]}
              getOptionLabel={(color) => color.name}
              onChange={(e, newValue) => {
                const selectedValues = newValue.map((option) => option.name);
                formik.setFieldValue('color', selectedValues);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Color" />}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Autocomplete
              fullWidth
              disablePortal
              id="combo-box-demo"
              SelectProps={{ native: false }}
              options={[...(yearList || [])]}
              getOptionLabel={(start_year) => start_year.name}
              onChange={(e, newValue) => {
                formik.setFieldValue('start_year', newValue.name);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Start Year"
                  {...getFieldProps('start_year')}
                  error={Boolean(touched.start_year && errors.start_year)}
                  helperText={touched.start_year && errors.start_year}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Autocomplete
              fullWidth
              disablePortal
              id="combo-box-demo"
              defaultValue={{name:moment().format('yyyy') }}
              SelectProps={{ native: false }}
              options={[...(yearList || [])]}
              getOptionLabel={(end_year) => end_year.name}
              onChange={(e, newValue) => {
                formik.setFieldValue('end_year', newValue.name);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="End Year"
                  {...getFieldProps('end_year')}
                  error={Boolean(touched.end_year && errors.end_year)}
                  helperText={touched.end_year && errors.end_year}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              fullWidth
              disablePortal
              id="combo-box-demo"
              SelectProps={{ native: false }}
              options={[...(variantList || [])]}
              getOptionLabel={(variant) => variant.name}
              onChange={(e, newValue) => {
                const selectedValues = newValue.map((option) => option.name);
                formik.setFieldValue('variant', selectedValues);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Variant" />}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              // multiple
              fullWidth
              disablePortal
              id="combo-box-demo"
              SelectProps={{ native: false }}
              options={[...(locationList || [])]}
              getOptionLabel={(location) =>
                location.name.charAt(0).toUpperCase() + location.name.slice(1)
              }
              onChange={(e, newValue) => {
                // console.log(newValue.name);
                // const selectedValues = newValue.map((option) => option.name);
                formik.setFieldValue('location', newValue.name);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id} style={{ textTransform: 'capitalize' }}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  {...getFieldProps('location')}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label={'Notes (Optional)'}
              type="string"
              multiline={true}
              rows={8}
              {...getFieldProps('notes')}
              error={Boolean(touched.notes && errors.notes)}
              helperText={touched.notes && errors.notes}
            />
          </Grid>
        </Grid>

        <Box mt={2} />
        {alert.visible && (
          <Alert
            onClose={() => {
              setalert({ visible: false, message: '' });
            }}
            severity="error"
            style={{ fontSize: 12, marginBottom: 20 }}
          >
            {Object.entries(alert?.message).map(([field, message]) => (
              <p key={field}>
                <b>{field}:</b> {message}
              </p>
            ))}
          </Alert>
        )}

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <LoadingButton
            sx={{ marginRight: 2, }}
            size="large"
            onClick={() => AddProduct()}
            variant="contained"
            loading={loading}
          >
            {t('AddAgent.buttons.1')}
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            {t('AddAgent.buttons.2')}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
}
