import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Check from '../asset/Check.png'
import Icons from '../asset/Icons.png'
import CheckWhite from '../asset/CheckWhite.png'
import IconsWhite from '../asset/IconsWhite.png'
import { useNavigate } from 'react-router-dom';


function Payment() {
  const navigate = useNavigate();

    const MonthlyData = [
        {text:"Enhanced Analytics"},
        {text:"Custom Domain"},
        {text:"E-commerce Integration"},
        {text:"Priority Support"},
        {text:"Advanced Security"},
    ]
    const YearlyData = [
        {text:"Advanced Marketing Tools"},
        {text:"Customizable Templates"},
        {text:"Multi-user Access"},
        {text:"Third-party Integrations"},
        {text:"24/7 Priority Support"},
    ]
  return (
    <Box>
      <Box width={"100%"} padding="20px">
        <Typography variant="h1" align='center' marginBottom={"16px"} color="#000000">Our Solutions to Industry</Typography>
        <Typography variant="subtitle1" align='center' marginBottom={"40px"} color={"#344054"}>
          Maximize efficiency and minimize headaches by centralizing your automotive inventory <br />
          management and invoicing tasks with My Yard Manager's{' '}
        </Typography>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'center',gap:"40px",flexWrap:"wrap", padding:"15px"}}>
        <Box
          style={{ padding: "40px", border: '1px solid #E7EBFF', borderRadius: "26px", marginBottom: "44px", boxShadow:"0px 26px 40px 0px rgba(188, 202, 255, 0.13)" }}
        >
          <img src={Icons} alt="Logo" />
          <Typography variant="body1" marginBottom={"14px"} marginTop={"20px"} sx={{fontSize:"44px",fontWeight:"500",color:"#1B223C"}}>Montly Plan</Typography>
          <Typography variant="body2" sx={{color:"#797878",fontSize:"24px",fontWeight:"300", marginBottom:"24px"}}>
            Unleash the Power of Your Business <br /> with 21 Days free trail.
          </Typography>
          <Typography display={"flex"} alignItems="baseline" variant="body2" sx={{color:"#1B223C",fontSize:"60px",fontWeight:"500"}}>
            $83 <Typography variant="body2" sx={{color:"#797878",fontSize:"24px",fontWeight:"300",marginLeft:"13px"}}>per month</Typography>
          </Typography>

          <Box style={{width:"100%",height:"2px",backgroundColor:"#E7EBFF", marginTop:"20px",marginBottom:"28px"}}></Box>

          <Box marginBottom={"44px"}>
            {MonthlyData.map((title)=>(
            <Box style={{ display: 'flex', alignItems: 'center', gap: "16px", marginBottom: "12px" }}>
                <img src={Check} alt="Logo" />
              <Typography variant="body2" sx={{fontSize:"24px",fontWeight:"400",color:"#1B223C"}}>{title.text}</Typography>
            </Box>
            ))}
          </Box>
          <Button
                onClick={() => {
                  navigate("/dashboard/employees", { replace: false })
                }}
                style={{width:"100%",color:"#1B223C",borderColor:"#1B223C"}}
                color="secondary"
                size={"large"}
                variant="outlined"
                // component={RouterLink}
                to="/dashboard"
                // startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Get Started
              </Button>
        </Box>

        <Box
          style={{ padding: "40px", border: '1px solid #E7EBFF', borderRadius: "26px", marginBottom: "44px",background:"#121212", boxShadow:"0px 26px 40px 0px rgba(188, 202, 255, 0.13)" }}
        >
          <img src={IconsWhite} alt="Logo" />
          <Typography variant="body1" marginBottom={"14px"} marginTop={"20px"} sx={{fontSize:"44px",fontWeight:"500",color:"#FFFFFF"}}>Yearly Plan</Typography>
          <Typography variant="body2" sx={{color:"#FFFFFF",fontSize:"24px",fontWeight:"300", marginBottom:"24px"}}>
          Take Your Business to the Next Level <br /> with 21 days free trails of yearly plan
          </Typography>
          <Typography display={"flex"} alignItems="baseline" variant="body2" sx={{color:"#78FFB6",fontSize:"60px",fontWeight:"500"}}>
            $83 <Typography variant="body2" sx={{color:"#FFFFFF",fontSize:"24px",fontWeight:"300",marginLeft:"13px"}}>per Year</Typography>
          </Typography>

          <Box style={{width:"100%",height:"2px",backgroundColor:"#E7EBFF", marginTop:"20px",marginBottom:"28px"}}></Box>

          <Box marginBottom={"44px"}>
            {YearlyData.map((title)=>(
            <Box style={{ display: 'flex', alignItems: 'center', gap: "16px", marginBottom: "12px" }}>
                <img src={CheckWhite} alt="Logo" />
              <Typography variant="body2" sx={{fontSize:"24px",fontWeight:"400",color:"#FFFFFF"}}>{title.text}</Typography>
            </Box>
            ))}
          </Box>
          <Button
                onClick={() => {
                 
                }}
                style={{width:"100%",color:"#121212",borderColor:"#1B223C",background:"#78FFB6"}}
                size={"large"}
                variant="outlined" color="secondary"
                // component={RouterLink}
                to="#"
              >
                Get Started
              </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Payment;
