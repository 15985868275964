import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Button, Box, Divider, MenuItem, Typography, Modal, useMediaQuery } from '@mui/material';
import  { useTheme } from '@mui/material/styles';
// import palette from 'src/theme/palette';

// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import { api } from 'src/Api';
import { AccountModal } from 'src/sections/@dashboard/myaccount';
import { UserPassword } from 'src/sections/@dashboard/user';
import pallete from '../../theme/palette';
import { useTranslation } from 'react-i18next';
//

import { LoadingButton } from '@mui/lab';
import { CompanyModal } from 'src/sections/@dashboard/mycompany';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useTranslation();

  const MENU_OPTIONS = [
    {
      label: t('dashboard.Account.1'),
      icon: 'eva:person-fill',
      linkTo: '#'
    },
    {
      label: t('dashboard.Account.4'),
      icon: 'mdi:company',
      linkTo: '#'
    }
  ];

  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    //   setaccountModal(true);
  };

  // console.log("before")
  const user = JSON.parse(localStorage.getItem('user'));
  // console.log("after")

  const [accountModal, setaccountModal] = useState(false);
  const handleAdminClose = () => {
    setOpen(false);
    setaccountModal(false);
  };
  const refreshAdmin = () => {
    setaccountModal(false);
  };

  const [companyModal, setCompanyModal] = useState(false);
  const handleOpenCompanyModal = () => {
    setCompanyModal(true);
  };
  const handleCloseCompanyModal = () => {
    setCompanyModal(false);
  }

  const style = {
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: '2px solid',
    borderRadius: 2,
    borderColor: pallete.primary.main,
    overflow: 'hidden'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  const [loading, setloading] = useState(false);
  // function Logout() {
  //   setloading(true);
  //   const myHeaders = new Headers();
  //   const token = JSON.parse(localStorage.getItem('token'));
  //   // myHeaders.append('Authorization', 'Bearer ' + token);

  //   myHeaders.append('Authorization', `Bearer ${token}`);
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  //   fetch(api.admin_logout, requestOptions)
  //     .then((response) => {
  //       console.log('Response:', response);
  //       return response.json();
  //     })
  //     .then((result) => {
  //       console.log('Parsed JSON:', result);
  //       if (result.success === true) {
  //         localStorage.clear();
  //         navigate('/login', { replace: true });
  //       }
  //     })
  //     .catch((error) => console.log('error', error))
  //     .finally(() => {
  //       setloading(false);
  //     });
  // }

  async function Logout() {
    await localStorage.clear();
    navigate('/login', { replace: true });
  }

  const [reset, setreset] = useState(false);
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
      {user != null && (
        <>
          <Modal
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            open={accountModal}
            onClose={handleAdminClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...boxStyle, style }} borderRadius={2} className="admin_modal_pa">
              <AccountModal data={user} handleClose={handleAdminClose} refresh={refreshAdmin} />
            </Box>
          </Modal>
          <Modal
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            open={companyModal}
            onClose={handleCloseCompanyModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                p: 0,
                width: !isTab && !isMobile ? '30%' : (isTab &&!isMobile) ? '60%' : '97%',
                minHeight: '60%',
                alignItems: 'center'
              }}
            >
              <CompanyModal handleClose={handleCloseCompanyModal} />
            </Box>
          </Modal>
          <Modal
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            open={reset}
            onClose={handleAdminClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                p: 0,
                width: '70%',
                minHeight: '60%',
                alignItems: 'center'
              }}
            >
              <UserPassword
                handleClose={() => {
                  setreset(false);
                }}
                refresh={() => {
                  setreset(false);
                }}
              />
            </Box>
          </Modal>

          <Button
            variant="contained"
            ref={anchorRef}
            onClick={handleOpen}
            to="#"
            startIcon={<Iconify icon={'gg:profile'} />}
            color="secondary"
            sx={{background:"#EDEDED",color:"#000",boxShadow:`0 2px 6px 0 rgba(0, 0, 0, 0.24)`,'&:hover':{backgroundColor:"#dedcdc"}}}
          >
            {t('dashboard.Account.0')}
          </Button>

          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {user?.firstname} {user?.lastname}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            {MENU_OPTIONS.map((option) => (
              <div key={option.label}>
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={() => {
                    if (option.label === t('dashboard.Account.1')) {
                      setOpen(false);
                      setaccountModal(true);
                    } else if (option.label === t('dashboard.Account.4')) {
                      handleOpenCompanyModal();
                    } else {
                      setOpen(false);
                      setreset(true);
                    }
                  }}
                  sx={{ typography: 'body2', py: 1, px: 2.5 }}
                >
                  <Iconify
                    icon={option.icon}
                    sx={{
                      mr: 2,
                      width: 24,
                      height: 24
                    }}
                  />

                  {option.label}
                </MenuItem>
                <Divider py={0} my={0} />
              </div>
            ))}

            <Box sx={{ p: 2, pt: 1.5 }}>
              <LoadingButton
                onClick={() => Logout()}
                fullWidth
                variant="outlined"
                color="secondary"
                loading={loading}
              >
                {t('dashboard.Account.3')}
              </LoadingButton>
            </Box>
          </MenuPopover>
        </>
      )}
    </>
  );
}
