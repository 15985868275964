import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import Alert from '@mui/material/Alert';
import { Encryption } from 'src/functions/function';
import { showSuccessAlert } from 'src/utils/toastifyAlert';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [isSubmitting, setisSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    username: Yup.string().required('User name is required'),
    companyName: Yup.string().required('Company name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    // country: Yup.string().required('Country is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      companyName: '',
      // country: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      Register();
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps } = formik;
  function Register() {
    setisSubmitting(true);
    const formData= new FormData();
    const body = {
      firstname: Encryption(values?.firstname, process.env.REACT_APP_SECRET_KEY),
      lastname: Encryption(values?.lastname, process.env.REACT_APP_SECRET_KEY),
      username: Encryption(values?.username, process.env.REACT_APP_SECRET_KEY),
      email: Encryption(values?.email, process.env.REACT_APP_SECRET_KEY),
      password: Encryption(values?.password, process.env.REACT_APP_SECRET_KEY),
      confirm_password: Encryption(values?.confirm_password, process.env.REACT_APP_SECRET_KEY),
      role: Encryption(2, process.env.REACT_APP_SECRET_KEY),
      company_name: Encryption(values?.companyName, process.env.REACT_APP_SECRET_KEY),
      // country: Encryption(values?.country, process.env.REACT_APP_SECRET_KEY)
    }

    for (const key in body) {
      formData.append(key, body[key]);
    }
    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
      header: 'multipart/form-data'
    };
    fetch(api.register, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        if (response.success === true) {
          showSuccessAlert('Registration successful');
          navigate(`/login?email=${values.email}`);
        } else {
          setalert({ visible: true, message: Object.values(response?.errors)[0][0] });
        }
      })
      .catch((error) => {
        setalert({ visible: true, message: 'error occured' });
      })
      .finally(() => {
        setisSubmitting(false);
      });
  }
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              required
              label="First Name"
              {...getFieldProps('firstname')}
              error={Boolean(touched.firstname && errors.firstname)}
              helperText={touched.firstname && errors.firstname}
            />
            <TextField
              fullWidth
              required
              type="text"
              label="Last Name"
              {...getFieldProps('lastname')}
              error={Boolean(touched.lastname && errors.lastname)}
              helperText={touched.lastname && errors.lastname}
            />
          </Stack>
            <TextField
              fullWidth
              required
              type="text"
              label="User Name"
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          <TextField
            required
            fullWidth
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            required
            type="text"
            label="Company name"
            {...getFieldProps('companyName')}
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
          />
          {/* <TextField
            fullWidth
            required
            type="text"
            select
            label="Country"
            {...getFieldProps('country')}
            error={Boolean(touched.country && errors.country)}
            helperText={touched.country && errors.country}
          >
            {
              country_list?.map((country) => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))
            }
          </TextField> */}

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('confirm_password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowConfirmPassword((prev) => !prev)}>
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirm_password && errors.confirm_password)}
            helperText={touched.confirm_password && errors.confirm_password}
          />
          {alert.visible && (
            <Alert
              style={{ marginTop: 20, marginBottom: 5 }}
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
            >
              {alert.message}
            </Alert>
          )}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Sign up
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
